import { ButtonProps } from './types';
import * as S from './styles';

export const Button = (props: ButtonProps) => {
    const buttonProps = {
        ...props,
        title: props.children?.toString() ?? ''
    };
    const inner = <S.ButtonText type={props.type}>{props.children}</S.ButtonText>;

    return props.type === 'floating' ? (
        <S.Floating {...buttonProps}>{inner}</S.Floating>
    ) : (
        <S.Button {...buttonProps}>{inner}</S.Button>
    );
};
