import { OrderedList } from '@elements/collections/OrderedList';
import { FeedbackScreen } from '@elements/feedback/FeedbackScreen';
import { Heading } from '@elements/typography/Heading';
import { Paragraph } from '@elements/typography/Paragraph';
import { useChannelsContext } from '@global/utils/nativescriptHost/ChannelsProvider';
import { handleDone } from './helpers';
import { ForgotPasswordSuccessProps } from './types';

export const ForgotPasswordSuccess = ({ route }: ForgotPasswordSuccessProps) => {
    const nextSteps = [
        <Paragraph key="next-steps-1">
            We sent an email to {route.params.email}. Please check your inbox and follow the instructions
        </Paragraph>,
        <Paragraph key="next-steps-2">
            If you&#39;re having trouble finding the email, try checking your spam folders or contact HazardCo support
        </Paragraph>
    ];
    const eventSender = useChannelsContext();

    return (
        <FeedbackScreen
            type="success"
            primaryButton={{ text: 'Done', handler: handleDone(eventSender), testID: 'DoneBtn' }}
            title="Email instructions sent"
            subtitle="Check your inbox to reset your password"
        >
            <Heading type="section">Next steps</Heading>
            <OrderedList items={nextSteps} />
        </FeedbackScreen>
    );
};
