export declare type Environments = 'hc1' | 'hc2' | 'hc3' | 'dev' | 'uat' | 'pra' | 'local' | 'localAndroid' | 'test';

export const environment: Environments = (process.env.ENV as Environments) ?? 'dev';
export const debug = process.env.DEBUG?.toLowerCase() === 'true';

export enum ServerlessAPI {
    PRODUCTION = 'https://api-pra.hazcoapp.com',
    UAT = 'https://api-uat.hazcoapp.com',
    DEVELOP = 'https://api-dev.hazcoapp.com',
    LOCALHOST = 'http://localhost:3000',
    LOCALHOSTANDROID = 'http://10.0.2.2:3000',
    TEST = 'https://api-tst.hazcoapp.com',
    HC1 = 'https://api-hc1.hazcoapp.com',
    HC2 = 'https://api-hc2.hazcoapp.com',
    HC3 = 'https://api-hc3.hazcoapp.com'
}

export enum FormsAPI {
    PRODUCTION = 'https://api-v1-production.hazardcoapp.com',
    UAT = 'https://api-v1-uat.hazardcoapp.com',
    DEVELOP = 'https://api-v1-dev.hazardcoapp.com',
    LOCALHOST = 'http://localhost:8888',
    LOCALHOSTANDROID = 'http://10.0.2.2:8888',
    TEST = 'https://api-v1-test.hazardcoapp.com',
    HC1 = 'https://api-v1-hc1.hazardcoapp.com',
    HC2 = 'https://api-v1-hc2.hazardcoapp.com',
    HC3 = 'https://api-v1-hc3.hazardcoapp.com',
    HC4 = 'https://api-v1-hc4.hazardcoapp.com'
}

const apisUrlMap: Record<Environments, [FormsAPI, ServerlessAPI]> = {
    uat: [FormsAPI.UAT, ServerlessAPI.UAT],
    dev: [FormsAPI.DEVELOP, ServerlessAPI.DEVELOP],
    local: [FormsAPI.LOCALHOST, ServerlessAPI.LOCALHOST],
    localAndroid: [FormsAPI.LOCALHOSTANDROID, ServerlessAPI.LOCALHOSTANDROID],
    test: [FormsAPI.TEST, ServerlessAPI.TEST],
    hc1: [FormsAPI.HC1, ServerlessAPI.HC1],
    hc2: [FormsAPI.HC2, ServerlessAPI.HC2],
    hc3: [FormsAPI.HC3, ServerlessAPI.HC3],
    pra: [FormsAPI.PRODUCTION, ServerlessAPI.PRODUCTION]
};

export const SERVERLESS_ENDPOINT: ServerlessAPI = apisUrlMap[environment][1] ?? ServerlessAPI.DEVELOP;
export const FORMS_ENDPOINT: FormsAPI = apisUrlMap[environment][0] ?? FormsAPI.DEVELOP;
