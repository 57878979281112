import styled from 'styled-components/native';

export const Modal = styled.Modal``;

export const Container = styled.SafeAreaView`
    height: 100%;
`;

export const Body = styled.ScrollView``;

export const Cancel = styled.TouchableOpacity`
    margin-top: 36px;
    padding: 0 16px;
`;

export const Text = styled.Text`
    font-size: 16px;
    font-weight: normal;
    color: #0091ea;
    font-family: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif" !important;
`;

export const Header = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
