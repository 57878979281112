import { NavigationProp, useNavigation } from '@react-navigation/native';
import { createContext, useContext } from 'react';
import { Channels, createEventSender, GenericWebViewInterface } from './nativescript-host';
import { init as createNSWebInterface } from '@global/utils/nativescriptHost/nativescript-webview-interface';
import { GlobalModulesParams } from '@global/routing';
import { WebViewEventKey } from './api';
import { Platform } from 'react-native';
import { createWebHostInterface } from './web-host';

type Navigation = NavigationProp<GlobalModulesParams>;

const createNativeAppInterface = (navigation: Navigation): GenericWebViewInterface => {
    const callbacks: Partial<Record<WebViewEventKey, (arg: any) => void>> = {};
    const handlers: Partial<Record<WebViewEventKey, (arg: any) => void>> = {
        navigate: () => navigation.navigate('Home')
    };

    return {
        emit: (eventName: string, data: unknown) => {
            handlers[eventName as WebViewEventKey]?.(data);
        },
        on: (eventName: string, callback: (arg: unknown) => void) =>
            (callbacks[eventName as WebViewEventKey] = callback)
    };
};

const hostInterfaces: Record<typeof Platform.OS, (...args: any[]) => GenericWebViewInterface> = {
    web: () => createWebHostInterface() ?? createNSWebInterface(),
    ios: createNativeAppInterface,
    android: createNativeAppInterface,
    macos: createNativeAppInterface,
    windows: createNativeAppInterface
};

export const ChannelsContext = createContext<Channels | undefined>(undefined);

export const useChannelsContext = () => useContext(ChannelsContext);

export const ChannelsProvider = ({ children }: { children: JSX.Element }) => {
    const wvInterface = hostInterfaces[Platform.OS](useNavigation());
    const channels = createEventSender(wvInterface);

    return <ChannelsContext.Provider value={channels}>{children}</ChannelsContext.Provider>;
};
