import { Option } from '../ActionItem';
import { RadioConfig } from './components/Radio';

export const mapOptionsToRadioConfig = (options: Option[], selected?: string): RadioConfig[] =>
    options.map(({ value, label, subtitle, disabled }) => ({
        id: value,
        title: label,
        value,
        subtitle,
        selected: value === selected,
        disabled
    }));
