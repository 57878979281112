import { Text } from '../../components/Text';

type HiddenFieldProps = {
    value: string;
    id: string;
};

export const HiddenField = (props: HiddenFieldProps) => {
    const hiddenProps = {
        ...props,
        label: props.id,
        name: props.id,
        type: 'hidden'
    } as const;
    return <Text {...hiddenProps} />;
};
