import { FeedbackScreen } from '@elements/feedback/FeedbackScreen';
import { Heading } from '@elements/typography/Heading';
import { Paragraph } from '@elements/typography/Paragraph';
import { useChannelsContext } from '@global/utils/nativescriptHost/ChannelsProvider';
import { events } from './metrics';
import { CreateTaskSuccessProps } from './types';

export const CreateTaskSuccess = ({ route, navigation }: CreateTaskSuccessProps) => {
    const { title, location, assignee } = route.params;
    const eventSender = useChannelsContext();

    return (
        <FeedbackScreen
            title="Task created"
            type="success"
            subtitle="This task can now be viewed on the tasks list"
            primaryButton={{
                text: 'Add another',
                handler: () => {
                    eventSender?.logEvent({ event: events.mainAction });
                    navigation.navigate('New task');
                },
                testID: 'AddAnotherBtn'
            }}
            secondaryButton={{
                text: 'Done',
                handler: () => navigation.navigate('Tasks'),
                testID: 'DoneBtn'
            }}
        >
            <Heading type="section">Task details</Heading>
            <Paragraph noMargin>{title}</Paragraph>
            {location && <Paragraph noMargin>{location}</Paragraph>}
            <Paragraph noMargin>Assigned to {assignee}</Paragraph>
        </FeedbackScreen>
    );
};
