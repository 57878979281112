import React, { useState, Fragment, useReducer } from 'react';
import _ from 'lodash';
import { Screen } from '@elements/layout/Screen';
import { TaskDetailsProps } from './types';
import { Heading } from '@elements/typography/Heading';
import { TasksService } from '../../services/tasks';
import { Paragraph } from '@elements/typography/Paragraph';
import { useUserContext } from '@context/UserProvider';
import { ActionItemSubmission } from '@elements/forms/ActionItem';
import { patchTaskWithActionItem, useHeaderOptions } from './helpers';
import { formatDateTime } from '@global/utils/helpers/date';
import { FooterButtons } from '@elements/layout/FooterButtons';
import { AddNoteModal } from './modals/AddNote/AddNote';
import { Button } from '@elements/fundamentals/Button';
import { ActivityFeed } from '@elements/collections/ActivityFeed';
import { useOnPageLoad } from '@shared/hooks/useOnPageLoad';
import { asyncWithFeedback } from '@UIUtils/asyncWithFeedback';
import { DynamicForm } from '@elements/forms/DynamicForm';
import { reducer } from './state';
import { screenName, events } from './metrics';
import { useChannelsContext } from '@global/utils/nativescriptHost/ChannelsProvider';

export const TaskDetails = (props: TaskDetailsProps) => {
    const { user, loading, sites, groupUsers, associatedGroupUsers } = useUserContext();

    const [{ task, creator, groupName, formTemplate, taskFormModel }, dispatch] = useReducer(reducer, {
        formTemplate: [],
        user
    });

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [modalVisible, setModalVisible] = useState(false);

    const eventSender = useChannelsContext();

    const closeModal = () => setModalVisible(false);

    const showAddNote = () => {
        eventSender?.logEvent({ event: events.addNote });
        setModalVisible(true);
    };

    const refreshFeedsRef = React.useRef<() => void>();
    const registerRefreshFeed = (callback: () => void) => (refreshFeedsRef.current = callback);

    useHeaderOptions(props.navigation, task, props.route.params?.isTransient);

    useOnPageLoad(
        user.isAuthenticated && !loading && props.route.params?.GUID
            ? asyncWithFeedback({
                  action: () => TasksService.getTaskByGUID(props.route.params.GUID),
                  postAction: result => {
                      if (result === undefined) return;
                      const { creator, task, groupName } = result;
                      dispatch({
                          type: 'LOAD_TASK',
                          creator,
                          task,
                          groupName,
                          teamMembers: groupUsers,
                          nonTeamMembers: associatedGroupUsers,
                          sites
                      });
                      refreshFeedsRef.current?.();
                  },
                  message: { error: "We couldn't load your task, maybe try again later" },
                  setIsLoading
              })
            : _.noop,
        props.navigation,
        [props.route.params?.GUID, user, loading, groupUsers, sites, associatedGroupUsers]
    );

    const updateTask = asyncWithFeedback({
        action: async (data: ActionItemSubmission, note?: string, images?: string[]) => {
            if (!task) return;

            const patchedTask = patchTaskWithActionItem(task, data);
            return TasksService.update({ ...patchedTask, Note: note, Attachments: images });
        },
        postAction: patchedTask => {
            if (!patchedTask) return;

            dispatch({ type: 'UPDATE_TASK', task: patchedTask });
            refreshFeedsRef.current?.();
        },
        message: { error: "We couldn't update the task right now, try again later" },
        onError: _.noop,
        setIsLoading
    });

    const mapModalChanges = (change?: ActionItemSubmission[]): Partial<{ note: string; images: string[] }> => {
        const mapper = {
            Note: {
                newProp: 'note',
                valueMap: _.identity
            },
            Photos: {
                newProp: 'images',
                valueMap: (x: string[]) => (x.length ? x : undefined)
            }
        };
        type ModalChange = keyof typeof mapper;

        return _(change)
            .filter(({ field }) => _.includes(_.keys(mapper), field))
            .map(({ value, field }) => [field, value])
            .map(([field, value]: [ModalChange, string[]]) => [mapper[field].newProp, mapper[field].valueMap(value)])
            .fromPairs()
            .value();
    };

    return (
        <Screen
            screenName={screenName}
            {...{ isLoading }}
            footer={
                <FooterButtons>
                    <Button onPress={showAddNote} type="primary" testID="AddNoteBtn">
                        Add note
                    </Button>
                    {modalVisible && (
                        <AddNoteModal
                            taskGUID={task?.GUID}
                            onClose={() => {
                                refreshFeedsRef.current?.();
                                closeModal();
                            }}
                        />
                    )}
                </FooterButtons>
            }
        >
            {task && creator && (
                <Fragment>
                    <Heading type="page">{task.Title}</Heading>
                    <Paragraph>{task.Description}</Paragraph>
                    <Paragraph color="muted" noMargin>
                        Created by: {creator.FirstName} {creator.LastName} ({groupName}).
                    </Paragraph>
                    <Paragraph color="muted">When: {formatDateTime(user, task.CreatedAt!)}</Paragraph>
                    <Heading type="section">Actions</Heading>

                    <DynamicForm
                        initialValue={taskFormModel}
                        template={formTemplate}
                        onChange={(patch, others) => {
                            if (typeof patch !== 'string' && typeof others !== 'string') {
                                const { images, note } = mapModalChanges(others);
                                updateTask(patch, note, images);
                            }
                        }}
                    />

                    <ActivityFeed
                        heading="Activity"
                        registerRefresh={registerRefreshFeed}
                        dataGetter={_.partial(TasksService.getActivitiesForTask, task.GUID ?? '')}
                        dataMapper={_.partial(TasksService.mapActivitiesToFeedItem, user)}
                        loadMoreTitle="Load older notes"
                    />
                </Fragment>
            )}
        </Screen>
    );
};
