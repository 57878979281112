import { FieldProps } from '../Field.type';
import { TextArea } from '../../../TextArea';

type Props = Record<never, unknown>;

type Validators = 'Required' | 'MinLength' | 'Email' | 'MaxLength' | 'Pattern';

export const TextAreaField = (props: FieldProps<string, Props, Validators>) => {
    const textAreaProps = {
        type: props.validators?.Email ? 'emailAddress' : 'text',
        label: props.title,
        name: props.title,
        onChangeText: props.onValueChange
    };

    return <TextArea {...{ ...props, ...textAreaProps }} />;
};
