import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { SERVERLESS_ENDPOINT, FORMS_ENDPOINT, debug } from '../../environment';

const requestLogger = (config: AxiosRequestConfig, endpoint: string) => {
    console.log(
        `REQUEST: ${config.method?.toUpperCase()} to '${endpoint}${config.url}' with body '${JSON.stringify(
            config.data
        )}'`
    );
    return config;
};

const responseLogger = (response: AxiosResponse) => {
    console.log(`RESPONSE: '${JSON.stringify(response.data)}'`);
    return response;
};

const FormsAPI = Axios.create({ baseURL: FORMS_ENDPOINT });
const ServerlessAPI = Axios.create({ baseURL: SERVERLESS_ENDPOINT });

if (debug) {
    FormsAPI.interceptors.response.use(responseLogger);
    ServerlessAPI.interceptors.response.use(responseLogger);
}

export { ServerlessAPI, FormsAPI, requestLogger };
