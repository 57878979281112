import styled from 'styled-components/native';

export const Item = styled.TouchableOpacity`
    padding: 16px 8px;
    flex-direction: row;
    width: 100%;
    border: solid transparent 0.5px;
    border-bottom-color: #bcbcbc;
    border-top-color: #bcbcbc;
    align-items: center;
`;

export const Icon = styled.View<{ color?: string }>`
    height: 48px;
    width: 48px;
    background-color: ${({ color }) => color ?? '#1F2129'};
    align-items: center;
    justify-content: center;
    border-radius: 48px;
`;

export const Content = styled.View`
    padding: 0 16px;
    max-width: 80%;
`;

export const Title = styled.Text`
    font-size: 16px;
    color: #8a8a8e;
`;

export const Value = styled.Text`
    font-size: 20px;
    font-weight: bold;
`;

export const Indicator = styled.View`
    margin-left: auto;
`;
