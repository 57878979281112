import _ from 'lodash';
import fp from 'lodash/fp';
import { safeFormatMediumDate, UserLocale } from '@global/utils/helpers/date';
import { ActionItemProps, ActionItemSubmission, Option } from './types';
import { TemplateNode, TemplateTabConfig } from '../DynamicForm';
import { normaliseOptions } from '../util';

export const getDefaultOption = (props: ActionItemProps, user: UserLocale) =>
    mapCoreOption(
        props.entity.Type,
        {
            icon: props.entity.Icon,
            options: normaliseOptions(props.options)
        },
        user,
        props.value
    );

export const mapCoreOption = (
    type: TemplateNode['Type'],
    configForTypes: { options?: Option[] | TemplateTabConfig[]; icon?: string },
    user: UserLocale,
    value?: string
): Option | undefined =>
    _.cond([
        [_.matches('RadioGroup'), _.constant(_.find(normaliseOptions(configForTypes.options ?? []), { value }))],
        [
            _.matches('DateField'),
            () =>
                _.flow(safeFormatMediumDate(user), label =>
                    label && value
                        ? {
                              value,
                              label,
                              color: 'green',
                              icon: configForTypes.icon ?? ''
                          }
                        : undefined
                )(value)
        ],
        [_.stubTrue, _.constant(undefined)]
    ])(type);

export const liftChildrenContent = (entity: TemplateNode) => {
    const { Children, ...item } = entity;
    return [item, ...(Children ?? [])] as const;
};

export const mapSupportingDto: (fields: Record<string, string>[]) => ActionItemSubmission[] = fp.flow(
    fp.flatMap(fp.toPairs),
    fp.map(([field, value]) => ({
        field,
        value,
        label: value
    }))
);

export const mapLabelValuePairs: (selected: Record<string, string>) => Record<string, string>[] = fp.flow(
    fp.toPairs,
    fp.map(x => [x]),
    fp.map(_.fromPairs)
);

export const mapSubmissionItem = (label: string, update?: Record<'label' | 'value', string>) =>
    _.merge(
        {
            field: label,
            label: '',
            value: ''
        },
        _.pick(update, ['label', 'value'])
    );
