import { ActionItemSubmission, Option } from '@elements/forms/ActionItem';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { ScreenProps } from '@global/types';
import { useChannelsContext } from '@global/utils/nativescriptHost/ChannelsProvider';
import { useHeaderRightButton } from '@shared/hooks/useHeaderRightButton/useHeaderRightButton';
import _ from 'lodash';
import { TaskDTO } from '../../shared/types';
import { events } from './metrics';

export const taskStatusOptions: Option[] = [
    { label: 'To Do', value: 'To do', icon: 'clock-outline', color: '#63646a' },
    { label: 'In Progress', value: 'In Progress', icon: 'circle-slice-5', color: '#63646a' },
    { label: 'Blocked', value: 'Blocked', icon: 'pause-circle-outline', color: '#FF6F00' },
    { label: 'Done', value: 'Done', icon: 'check-circle-outline', color: '#43A047' },
    { label: "Won't Do", value: "Won't do", icon: 'close-circle-outline', color: '#E53935' }
];

export const taskPriorityOptions: Option[] = [
    { label: 'Low', value: 'Low', icon: 'chevron-down-circle-outline', color: '#5C6BC0' },
    { label: 'Medium', value: 'Medium', icon: 'minus-circle-outline', color: '#FF6F00' },
    { label: 'High', value: 'High', icon: 'chevron-up-circle-outline', color: '#E53935' },
    { label: 'Critical', value: 'Critical', icon: 'alert-outline', color: '#E53935' }
];

export const patchTaskWithActionItem = (task: TaskDTO, { label, value, field }: ActionItemSubmission): TaskDTO => {
    const patch = _.cond<string, Partial<TaskDTO>>([
        [
            _.matches('Site location'),
            _.constant({
                Address: value ? label : '',
                ProjectGUID: value
            })
        ],
        [
            _.matches('Assigned to'),
            _.constant({
                AssigneeName: label,
                Assignee: value
            })
        ],
        [
            _.matches('Due date'),
            _.constant({
                DueDate: new Date(value).valueOf()
            })
        ],
        [
            _.matches('Photos'),
            _.constant({
                Images: [...(task.Images ?? []), value]
            })
        ],
        [
            _.stubTrue,
            _.constant({
                [field]: value
            })
        ]
    ])(field);
    return {
        ...task,
        ...patch
    };
};

export const useHeaderOptions = (
    navigation: ScreenProps['navigation'],
    task?: TaskDTO,
    isTransient?: boolean | undefined
) => {
    const eventSender = useChannelsContext();
    const { showActionSheetWithOptions } = useActionSheet();

    const screenOptions = [
        {
            display: 'Edit',
            action: () => {
                if (!task) return;

                eventSender?.logEvent({ event: events.options.edit });
                navigation.navigate('Update task', {
                    task,
                    isTransient
                });
            }
        },
        {
            display: 'Cancel',
            action: _.noop
        }
    ];
    const cancelButtonIndex = screenOptions.findIndex(x => x.display === 'Cancel');

    useHeaderRightButton(navigation, () =>
        showActionSheetWithOptions(
            {
                options: screenOptions.map(x => x.display),
                message: 'Options',
                cancelButtonIndex
            },
            selected => screenOptions[selected ?? cancelButtonIndex].action()
        )
    );
};
