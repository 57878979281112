import { PartialRecord } from '@global/utils/type';
import _ from 'lodash/fp';
import { Effect } from './effect';
import { createPredicate, Operator, Transform } from './operator';

type CauseEffects<T> = {
    predicate: (value: T) => boolean;
    effects: Effect[];
};

/* A map from trigger fields to target fields to its Predicate and Effects pairs */
export type Trigger<T extends object> = PartialRecord<keyof T, PartialRecord<keyof T, CauseEffects<T>[]>>;

export const evaluateEffects = <T extends object, K extends keyof T>(
    value: T,
    changeKey: K,
    changeValue: T[K],
    trigger: Trigger<T>
): PartialRecord<K, Effect[]> => {
    const newValue = { ...value, ...{ [changeKey]: changeValue } };
    return _.pipe(
        _.get(changeKey),
        _.mapValues(
            _.pipe(
                _.filter((t: CauseEffects<T>) => t.predicate(newValue)),
                _.defaultTo<CauseEffects<T>[]>([]),
                _.flatMap(x => x.effects),
                _.compact
            )
        )
    )(trigger);
};

type Condition<T extends object> = {
    VisualEffect: Effect;
    Field: {
        ID: keyof T;
        Transform: Transform;
        Operator: Operator;
        Value: unknown;
    };
};

export const createTrigger = <T extends object>(
    fieldConditions: PartialRecord<keyof T, Condition<T>[]>
): Trigger<T> => {
    return _.pipe(
        _.toPairs,
        _.map(([targetProp, conditions]) =>
            conditions.map((c: Condition<T>) => [
                c.Field.ID,
                {
                    [targetProp]: {
                        predicate: createPredicate(
                            c.Field.ID as string,
                            c.Field.Operator,
                            c.Field.Value,
                            c.Field.Transform
                        ),
                        effects: [c.VisualEffect]
                    }
                }
            ])
        ),
        _.reject(_.isEmpty),
        _.flatten,
        _.groupBy(_.head),
        _.mapValues(_.pipe(_.flatMap(_.tail), _.groupBy(_.keys), _.mapValues(_.pipe(_.flatMap(_.values)))))
    )(fieldConditions) as Trigger<T>;
};
