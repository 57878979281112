import { AppError } from '@global/screens/AppError';
import { useChannelsContext } from '@global/utils/nativescriptHost/ChannelsProvider';
import ErrorBoundary from 'react-native-error-boundary';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { logError } from '@global/utils/nativescriptHost/helper';
import React from 'react';

interface ErrorScreenProps {
    error: Error;
    resetError: () => void;
}

export const CustomErrorBoundary = ({ children }: { children: React.ReactNode }) => {
    const channels = useChannelsContext();
    const errorId = uuidv4();

    const ErrorScreen = (props: ErrorScreenProps) => (
        <AppError {...props} error={props.error.message} errorId={errorId} />
    );

    return (
        <ErrorBoundary onError={_.partial(logError, _, channels, errorId)} FallbackComponent={ErrorScreen}>
            {children}
        </ErrorBoundary>
    );
};
