import _ from 'lodash/fp';
import { FieldType, PrimitiveFieldType } from '../fields/supportedFields';
import { TemplateNode } from '../types';
import { arrayValidator, YupArray } from './array';
import { numberValidator, YupNumber } from './number';
import { YupString, stringValidator } from './string';
import { Validator } from './type';

export type Yup = YupString | YupArray | YupNumber;

export type YupExprUnary = (validation: Yup) => Yup;
export type YupExpr<T = any> = (node: Omit<TemplateNode, 'Validators'>, option: T) => YupExprUnary | undefined;

export const chain =
    (of: () => Yup) =>
    (exprs: YupExprUnary[]): Yup =>
        exprs.reduce((p, v) => v(p), of());

type ValidatorType = 'string' | 'number' | 'array';
const validatorGroup: Record<PrimitiveFieldType, ValidatorType> = {
    Hidden: 'string',
    Dropdown: 'string',
    RadioGroup: 'string',
    TextArea: 'string',
    Text: 'string',
    ListPicker: 'string',
    DateField: 'number',
    DatePicker: 'number',
    FileUpload: 'array'
};

export const getValidator = (t: FieldType) => {
    return _.pipe(
        x => _.get(x, validatorGroup),
        _.cond([
            [_.equals('string'), _.constant(stringValidator as Validator<Yup>)],
            [_.equals('number'), _.constant(numberValidator as Validator<Yup>)],
            [_.equals('array'), _.constant(arrayValidator as Validator<Yup>)]
        ])
    )(t);
};
