import { registerRootComponent } from 'expo';
import { Platform } from 'react-native';
import { App } from './App';
import 'react-native-get-random-values'; // to polyfill uuid (see https://github.com/LinusU/react-native-get-random-values#usage)

registerRootComponent(App);

/**
 * Set viewport for nativescript webview
 * @TODO: https://necolas.github.io/react-native-web/docs/rendering/
 */
<script>
    {Platform.OS === 'web' &&
        setTimeout(() => {
            const meta = document.querySelector('meta[name="viewport"]');
            if (meta) {
                meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no');
            }
        }, 0)}
</script>;
