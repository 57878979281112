import { ListView } from '@elements/collections/ListView';
import React, { useCallback, useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { TabView, TabBar } from 'react-native-tab-view';
import { RenderChildProps, TabRoute, TabsProps } from './types';
import { tabBarStyles } from './styles';
import _ from 'lodash';

export const Tabs = <T extends string>({ emptyState, onItemTap, onLoadMore, ...props }: TabsProps<T>) => {
    const layout = useWindowDimensions();

    const [index, setIndex] = useState<number>(0);
    const [routes, setRoutes] = useState<TabRoute<T>[]>([]);

    const renderScene = useCallback(
        ({ route }: RenderChildProps) => {
            const label = route.key;
            const { items, showLoadMore } = _.find(props.tabs, { label })!;
            return (
                <ListView
                    key={`${label}-tab`}
                    {...{
                        items,
                        emptyState,
                        onTap: onItemTap,
                        onLoadMore: () => onLoadMore?.(label),
                        showLoadMore
                    }}
                />
            );
        },
        [emptyState, props.tabs]
    );

    useEffect(() => setIndex(props.startingIndex ?? 0), [props.startingIndex]);

    useEffect(() => {
        props.tabs.length && setRoutes(props.tabs.map(({ label }) => ({ key: label, title: label })));
    }, [props.tabs]);

    const handleTabChange = (tabIndex: number) => {
        setIndex(tabIndex);
        props.onTabChange?.(routes[tabIndex].title);
    };

    return routes.length ? (
        <TabView
            navigationState={{ index, routes }}
            renderScene={props.renderChild ?? renderScene}
            onIndexChange={handleTabChange}
            renderTabBar={props => (
                <TabBar {...props} {...tabBarStyles} getTestID={scene => `${scene.route.title}_TabItem`} />
            )}
            initialLayout={{ width: layout.width }}
        />
    ) : null;
};
