import styled from 'styled-components/native';
import { StyledAvatarProps } from './types';

export const Avatar = styled.View<StyledAvatarProps>`
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    border-radius: ${({ size }) => size}px;
    background: ${({ background }) => background ?? '#8a8a8e'};
    justify-content: center;
    align-items: center;
`;

export const Text = styled.Text<Pick<StyledAvatarProps, 'size'>>`
    font-weight: bold;
    color: white;
    font-size: ${({ size }) => size * 0.5 ?? 16}px;
`;
