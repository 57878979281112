import _ from 'lodash/fp';
import { NodeValidators, TemplateNode } from './types';
import { chain, getValidator } from './validators/core';

export const createFieldSchema = (field: Pick<TemplateNode, 'Options' | 'Validators' | 'Type' | 'Title'>) => {
    const { supported, of } = getValidator(field.Type);

    return _.pipe(
        _.pick(_.keys(supported)),
        _.toPairs,
        _.map(([k, v]: [keyof NodeValidators, any]) => supported[k]?.(field, v)),
        _.compact,
        chain(() => of().label(field.Title))
    )(field.Validators);
};

export const getDefaultValue = (fields: Readonly<{ ID: string; Value?: unknown }[]>) =>
    _.pipe(
        _.map(_.pipe(_.over([fieldAccessKey, _.get('Value')]), ([path, value]) => _.set(path, value, {}))),
        _.mergeAll
    )(fields);

export const fieldAccessKey = (props: { id: string } | { ID: string }) => ('id' in props ? props.id : props.ID);
