export const createWebHostInterface = () => {
    // not wrapped inside iframe
    if (window.location === window.parent.location) return;

    const callbacks = new Map();
    window.onmessage = function ({ data }) {
        if (!data) return;

        const { eventName, data: returnData } = JSON.parse(data);
        callbacks.get(eventName)?.(returnData);
    };
    return {
        emit: (eventName: string, data: unknown) => {
            window.top?.postMessage(
                JSON.stringify({
                    eventName,
                    data
                }),
                '*'
            );
        },
        on: (eventName: string, callback: (arg: unknown) => void) => {
            callbacks.set(eventName, callback);
        }
    };
};
