import { ScreenProps } from '@global/types';
import { useCallback, useEffect } from 'react';

/**
 * A hook for performing a particular effect or action on page load.
 * The difference between this hook and the normal `useEffect` hook is that
 * in this hook the effect will also occur when React Navigation handles a 'back' action,
 * whereas in `useEffect` the action will only occur on the initial mount.
 *
 * @param effect The action to perform when the component is viewed
 * @param navigation Navigation prop provided by React Navigation. This is needed to attach the necessary event listeners.
 * @param dependencies Dependencies for the effect, variables that should trigger a re-run on the effect when changed.
 */
export const useOnPageLoad = (effect: () => void, navigation: ScreenProps['navigation'], dependencies: any[]) => {
    const effectAsCallback = useCallback(effect, [...dependencies]);

    useEffect(() => {
        effectAsCallback();
        return navigation.addListener('focus', effectAsCallback);
    }, [effectAsCallback, navigation]);
};
