import { Channels } from './nativescript-host';
import { v4 as uuidv4 } from 'uuid';

const formatError = (err: Error) => JSON.stringify(err, Object.getOwnPropertyNames(err));

export const logError = (error: Error, channels?: Channels, errorId = uuidv4()): string => {
    channels?.log({
        message: error.message,
        data: { error: formatError(error), errorId },
        type: 'Error'
    });

    return errorId;
};
