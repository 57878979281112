import _ from 'lodash';
import ReactTimeAgo from 'react-time-ago';
import { DateTime } from 'luxon';
import { ReactElement } from 'react';
import { safeFormatShortDate } from '@global/utils/helpers/date';
import { useUserContext } from '@context/UserProvider';
import { RelativeDateProps } from './types';
import * as S from './styles';

export const RelativeDate = ({ date, prefix }: RelativeDateProps) => {
    const {
        user: { Locale, State }
    } = useUserContext();
    const prefixWithPadding = prefix ? _.concat(prefix, ' ') : '';

    // Determine dates to compare the incoming date against
    const asJSDate = new Date(date);
    const today = DateTime.fromJSDate(new Date()).minus({ hours: 12 }).toJSDate();
    const [tomorrow, inAWeek] = [1, 7].map(days => DateTime.fromJSDate(today).plus({ days }).toJSDate());

    // Organise UI variations based on date status
    const relativeDate = (
        <>
            {prefixWithPadding}
            <ReactTimeAgo date={asJSDate} locale="en-US" component={S.Display} />
        </>
    );
    const pastUI = <S.Display important>{relativeDate}</S.Display>;
    const todayUI = <S.Display important>{prefixWithPadding} today</S.Display>;
    const thisWeekUI = <S.Display>{relativeDate}</S.Display>;
    const futureUI = (
        <S.Display>
            {prefixWithPadding}
            {safeFormatShortDate({ State, Locale })(asJSDate)}
        </S.Display>
    );

    return _.cond<Date, ReactElement>([
        [x => _.lt(x, today), _.constant(pastUI)],
        [x => _.lt(x, tomorrow), _.constant(todayUI)],
        [x => _.lt(x, inAWeek), _.constant(thisWeekUI)],
        [_.stubTrue, _.constant(futureUI)]
    ])(asJSDate);
};
