import { TemplateNode } from '@elements/forms/DynamicForm/types';

export const LoginForm: TemplateNode[] = [
    {
        Type: 'Text',
        ID: 'email',
        Title: 'Email',
        Value: '',
        Validators: { Required: true, Email: true },
        AutoCorrect: false
    },
    {
        Type: 'Text',
        ID: 'password',
        Title: 'Password',
        Value: '',
        Validators: { Required: true },
        IsPassword: true
    }
];
