import { TemplateNode } from '@elements/forms/DynamicForm/types';

export const ForgotPasswordForm: TemplateNode[] = [
    {
        Type: 'Text',
        ID: 'email',
        Title: 'Email',
        Value: '',
        Validators: { Required: true, Email: true }
    }
];
