import _ from 'lodash';
import fp from 'lodash/fp';
import { createContext, useCallback, useContext, useState } from 'react';
import { BackgroundActivityContext, BackgroundActivityProviderProps } from './types';

const TheBackgroundActivityContext = createContext<BackgroundActivityContext>({
    isActivityBlocked: _.stubFalse,
    startBlockingActivity: _.noop,
    stopBlockingActivity: _.noop
});

export const useBackgroundActivties = () => useContext(TheBackgroundActivityContext);

export const BackgroundActivityProvider = (props: BackgroundActivityProviderProps) => {
    const [activties, setActivities] = useState<string[]>([]);

    const isActivityBlocked = _.partial(_.includes, activties);

    const stopBlockingActivity = useCallback(
        (name: string) => setActivities(fp.reject<string>(fp.eq(name))),
        [setActivities]
    );

    const startBlockingActivity = useCallback(
        (name: string) => setActivities(_.flow(fp.concat(name), fp.uniq)),
        [setActivities]
    );

    return (
        <TheBackgroundActivityContext.Provider
            value={{ isActivityBlocked, startBlockingActivity, stopBlockingActivity }}
        >
            {props.children}
        </TheBackgroundActivityContext.Provider>
    );
};
