import { LogEvent, ScreenName } from '@global/metrics/types';
import { User } from '@shared/types';

export type ProcessResult<Success, Failure> =
    | {
          type: 'success';
          result: Success;
      }
    | {
          type: 'failure';
          error: Failure;
      };

export const API_LEVEL = 1;

export type State = {
    userToken: string;
    user: User;
};

// after updating this definition, also update `webViewEventKey` array elements
export type WebViewEvent = {
    navigate: {
        input: { action: { url: string } | 'goBack'; clearHistory?: boolean };
        output: ProcessResult<void, string>;
    };
    handshake: {
        input: { appVersion: string };
        output: ProcessResult<void, string>;
    };
    getState: {
        input: { key: (keyof State)[] };
        output: ProcessResult<Partial<State>, string>;
    };
    patchState: {
        input: { state: Partial<State> };
        output: ProcessResult<void, string>;
    };
    log: {
        input: { message: string } & (
            | { data: { error: string; errorId: string }; type: 'Error' }
            | { data?: any; type: 'Info' | 'Debug' }
        );
        output: ProcessResult<void, string>;
    };
    tagScreen: {
        input: { screenName: ScreenName };
        output: ProcessResult<void, string>;
    };
    logEvent: {
        input: { event: LogEvent };
        output: ProcessResult<void, string>;
    };
};

export type WebViewEventKey = keyof WebViewEvent;

export const webViewEventKey: WebViewEventKey[] = [
    'navigate',
    'handshake',
    'getState',
    'patchState',
    'log',
    'tagScreen',
    'logEvent'
];
