import React from 'react';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { SearchBar } from '../SearchBar';
import { Tabs } from '../Tabs';
import { TabScreenProps } from './types';
import * as S from './styles';
import { LoadingScreen } from '@elements/feedback/LoadingScreen';
import { Button } from '@elements/fundamentals/Button';

export const TabScreen = <T extends string>({ searchBar, isLoading, floatingButton, ...props }: TabScreenProps<T>) => {
    return (
        <>
            <S.Background>
                <SearchBar {...searchBar} isHeader />
                <Tabs {...props} />
            </S.Background>
            <S.ButtonWrapper>
                <Button type="floating" onPress={floatingButton.handler} testID={`${floatingButton.label}Btn`}>
                    <S.Icon>
                        <Icon name={(floatingButton.icon as any) ?? 'plus-thick'} size={12} color="#ffffff" />
                    </S.Icon>
                    {floatingButton.label}
                </Button>
            </S.ButtonWrapper>
            {isLoading && <LoadingScreen />}
        </>
    );
};
