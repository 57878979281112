import { Screen } from '@elements/layout/Screen';
import { GlobalModulesParams, modularizedScreens } from '@global/routing';
import { Button } from 'react-native';
import _ from 'lodash';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

export const Home = ({ navigation }: NativeStackScreenProps<GlobalModulesParams, 'Home'>) => (
    <Screen>
        {_(modularizedScreens)
            .flatMap('screens')
            .filter(_.property('isEntryPoint'))
            .map('name')
            .map((name: keyof GlobalModulesParams, index) => (
                <Button key={index} onPress={() => navigation.navigate(name)} title={name} testID={name}></Button>
            ))
            .value()}
    </Screen>
);
