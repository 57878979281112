import { OrderedList } from '@elements/collections/OrderedList';
import { FeedbackScreen } from '@elements/feedback/FeedbackScreen';
import { Heading } from '@elements/typography/Heading';
import { Paragraph } from '@elements/typography/Paragraph';
import { useChannelsContext } from '@global/utils/nativescriptHost/ChannelsProvider';

export const ServerError = () => {
    const items = [
        <Paragraph key="next-steps-1">Try completing the action again</Paragraph>,
        <Paragraph key="next-steps-2">Close and restart the app</Paragraph>,
        <Paragraph key="next-steps-2">Contact HazardCo Support</Paragraph>
    ];
    const eventSender = useChannelsContext();

    return (
        <FeedbackScreen
            type="error"
            primaryButton={{
                text: 'OK',
                handler: () => eventSender?.navigate({ action: { url: 'dashboard' } }),
                testID: 'OkBtn'
            }}
            title="Server error"
            subtitle="Sorry, there's an issue with our system"
        >
            <Heading type="section">Things you can try</Heading>
            <OrderedList items={items} />
        </FeedbackScreen>
    );
};
