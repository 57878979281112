import { NewTaskProps } from './types';
import { TaskFormPage } from '../../components/TaskFormPage';
import { events, screenName } from './metrics';

export const NewTask = (props: NewTaskProps) => {
    return (
        <TaskFormPage
            heading="Create a task"
            subheading="Add details about the the task to be done and assign it to the appropriate person."
            submitAction="create"
            navigation={props.navigation}
            onSubmit={params => props.navigation.navigate('Create task success', params)}
            primaryButton={{
                label: 'Add',
                testID: 'AddBtn'
            }}
            metrics={{
                screenName,
                events
            }}
        />
    );
};
