import { TextStyle } from 'react-native';

export const Dropdown: TextStyle = {
    borderStyle: 'solid',
    borderWidth: 2,
    padding: 16,
    borderRadius: 2,
    fontSize: 16
};

export const Label: TextStyle = { fontWeight: 'bold', color: 'black' };
