import _ from 'lodash';
import { Option } from './ActionItem';
import { TemplateTabConfig } from './DynamicForm';

export const getInputBorderColor = (hasErrors?: boolean, isFocused?: boolean, disabled?: boolean) => {
    if (hasErrors) {
        return 'red';
    }
    if (isFocused) {
        return '#0091EA';
    }
    if (disabled) {
        return '#eeeeee';
    }

    return '#bcbcbc';
};

export const getInputBackgroundColor = (disabled?: boolean) => (disabled ? '#f5f5f5' : '#ffffff');

export const getInputTextColor = (disabled?: boolean) => (disabled ? '#6d6d6d' : '#111111');

export const hasTabbedOptions = (options: Option[] | TemplateTabConfig[]): options is TemplateTabConfig[] =>
    !!_.first(options as TemplateTabConfig[])?.Label;

/**
 * Converts a list of tabbed or non-tabbed Options into a single flattened list of Options.
 *
 * @param options List of tabbed or non-tabbed options
 * @returns A flattened list
 */
export const normaliseOptions = (options: Option[] | TemplateTabConfig[]): Option[] =>
    hasTabbedOptions(options) ? _.flatMap(options, 'Options') : options;
