import { useEffect } from 'react';
import { FormsAPI, ServerlessAPI, requestLogger } from '@global/services/api.config';
import { useUserContext } from '@context/UserProvider';

/**
 * Intercept Http Requests
 * Add user's ApiKey to every request when authenticated
 * Add the param for platform to every request
 */
export const Interceptor = ({ children }: { children: JSX.Element }) => {
    const { user } = useUserContext();

    useEffect(() => {
        const interceptorIds = [FormsAPI, ServerlessAPI].map(api => ({
            api,
            id: api.interceptors.request.use(({ headers, params, ...config }) =>
                requestLogger(
                    {
                        ...config,
                        ...params,
                        headers: {
                            ...headers,
                            Authorization: user.isAuthenticated
                                ? 'Bearer ' + user.token
                                : (headers?.Authorization as string)
                        }
                    },
                    config.baseURL as string
                )
            )
        }));

        return () => {
            // remove previous interceptor
            interceptorIds.forEach(({ api, id }) => api.interceptors.request.eject(id));
        };
    }, [user.token, user.isAuthenticated]);

    return children;
};
