import { wait } from '@shared/helpers';
import Axios from 'axios';
import _ from 'lodash';
import fp from 'lodash/fp';
import { ServerlessAPI } from '../api.config';
import { UploadableFeatures, UploadableItem, URLWithFilename } from './types';

export const getUploadURLForFiles = async (files: UploadableItem[], feature: UploadableFeatures) => {
    if (process.env.IS_STORYBOOK) {
        await wait();
        return files.map(({ filename, originalFilename }) => ({
            filename,
            originalFilename,
            url: ''
        }));
    }

    const { data } = await ServerlessAPI.post<URLWithFilename[]>('/file/redeem-upload-urls', { files, feature });
    return data.map(({ filename, url }) => {
        const { originalFilename } = _.find(files, { filename }) as UploadableItem;
        return {
            filename,
            originalFilename,
            url
        };
    });
};

export const mapFilesToPayload = (files: File[]): UploadableItem[] =>
    files.map(({ type, name }, index) => ({
        originalFilename: name,
        filename: `${Date.now()}_${index}`,
        contentType: type
    }));

export const uploadFiles = async (filelist: FileList, feature: UploadableFeatures) => {
    const files = convertFileListToArray(filelist);
    const mapped = mapFilesToPayload(files);
    const urlsAndFilenames = await getUploadURLForFiles(mapped, feature);
    return uploadToS3(files, urlsAndFilenames);
};

export const convertFileListToArray = (files: FileList): File[] =>
    _.flow(
        fp.times(index => files.item(index)),
        fp.compact
    )(files.length);

export const uploadToS3 = (files: File[], urls: URLWithFilename[]) => {
    const requests = urls.map(({ filename, url, originalFilename }) => {
        const data = _.find(files, { name: originalFilename }) as File;
        if (process.env.IS_STORYBOOK) {
            return Promise.resolve({ filename, originalFilename });
        }
        return Axios.put(url, data, {
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            headers: {
                'Content-Type': data.type
            }
        }).then(() => ({
            filename,
            originalFilename
        }));
    });

    return Promise.all(requests);
};

export const FilesService = {
    uploadFiles
};
