import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { Button } from '@elements/fundamentals/Button';
import { FooterButtons } from '@elements/layout/FooterButtons';
import { Screen } from '@elements/layout/Screen';
import { Heading } from '@elements/typography/Heading';
import { FeedbackScreenProps } from './types';
import { getBackgroundImage, getIcon } from './helpers';
import * as S from './styles';

export const FeedbackScreen = (props: FeedbackScreenProps) => {
    return (
        <Screen static background={getBackgroundImage(props.type)}>
            <S.Main>
                <S.Icon testID={`Icon-${props.type}`}>
                    <Icon name={getIcon(props.type)} color="#ffffff" size={48} />
                </S.Icon>
                <Heading type="page" variant="white" testID="FeedbackScreenTitle">
                    {props.title}
                </Heading>
                <Heading type="section" variant="white" testID="FeedbackScreenSubtitle">
                    {props.subtitle}
                </Heading>
            </S.Main>
            <S.Container>{props.children}</S.Container>
            <FooterButtons>
                {props.secondaryButton && (
                    <Button
                        type="secondary"
                        halfSize
                        onPress={props.secondaryButton.handler}
                        testID={props.secondaryButton.testID ?? 'FeedbackScreenSecondaryButton'}
                    >
                        {props.secondaryButton.text}
                    </Button>
                )}
                <Button
                    type="primary"
                    halfSize={!!props.secondaryButton}
                    onPress={props.primaryButton.handler}
                    testID={props.primaryButton.testID ?? 'FeedbackScreenPrimaryButton'}
                >
                    {props.primaryButton.text}
                </Button>
            </FooterButtons>
        </Screen>
    );
};
