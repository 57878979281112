import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { TasksProps } from './types';
import { TasksService } from '../../services/tasks';
import { TaskDTO } from '../../shared/types';
import { TabConfig } from '@elements/layout/Tabs';
import { applyFilter, TabScreen, TabState } from '@elements/layout/TabScreen';
import { TaskTabLabels } from '../../services/tasks/types';
import { ListItemConfig } from '@elements/collections/ListItem';
import { useUserContext } from '@context/UserProvider';
import { useOnPageLoad } from '@shared/hooks/useOnPageLoad';
import {
    mapToListItemWithDeleteAction,
    INITIAL_TAB_STATE,
    TASK_TYPES,
    EMPTY_STATE_CONFIG,
    getTabNameForTask
} from './helpers';
import { asyncWithFeedback } from '@UIUtils/asyncWithFeedback';
import { metrics } from './metrics';
import { useChannelsContext } from '@global/utils/nativescriptHost/ChannelsProvider';
import { useLogEvent, useLogScreenName } from '@global/metrics/hooks';

export const Tasks = (props: TasksProps) => {
    const { user, loading } = useUserContext();
    const [isLoading, setIsLoading] = useState(true);
    const [activeTab, setActiveTab] = useState<TaskTabLabels>('Mine');
    const [tasksState, setTasksState] = useState<TabState<TaskTabLabels, ListItemConfig, TaskDTO>>(INITIAL_TAB_STATE);
    const eventSender = useChannelsContext();

    useLogScreenName(metrics.screenName);
    useLogEvent(metrics.events.tabNav[activeTab]);

    const handleDelete = asyncWithFeedback({
        action: async (task: TaskDTO) => {
            await TasksService.deleteTask(task.GUID ?? '');
            eventSender?.logEvent({ event: metrics.events.deleteTask });
            return task;
        },
        postAction: task => {
            if (task === undefined) return;
            const tab = getTabNameForTask(task, user.GUID ?? '');
            setTasksState(existing => {
                const items = existing[tab].items.filter(({ GUID }) => task.GUID !== GUID);
                return {
                    ...existing,
                    [tab]: {
                        ...[existing[tab]],
                        items,
                        filteredItems: applyFilter(items, existing[tab].searchText)
                    }
                };
            });
        },
        onError: _.noop,
        message: { error: "The task couldn't be deleted right now, try again a bit later", success: 'Task deleted' },
        setIsLoading
    });

    const mapToListItem = (data: TaskDTO[]) => mapToListItemWithDeleteAction(data, handleDelete);

    const handleSearch = (input: string) => {
        setTasksState(previousState => ({
            ...previousState,
            [activeTab]: {
                ...previousState[activeTab],
                filteredItems: applyFilter(previousState[activeTab].items, input),
                searchText: input
            }
        }));
    };

    const handleAddNew = () => {
        props.navigation.navigate('New task');
    };

    const handleLoadMore = asyncWithFeedback({
        action: (tab: TaskTabLabels) =>
            TasksService.getPage(tab, tasksState[tab].lastEvaluatedKey).then(x => ({ ...x, tab })),
        postAction: result => {
            if (result === undefined) return;
            const { data, lastEvaluatedKey, tab } = result;
            return setTasksState(existing => {
                const allItemsForTab = [...existing[tab].items, ...mapToListItem(data)];
                return {
                    ...existing,
                    [tab]: {
                        ...existing[tab],
                        items: allItemsForTab,
                        filteredItems: applyFilter(allItemsForTab, existing[tab].searchText),
                        lastEvaluatedKey
                    }
                };
            });
        },
        message: { error: "We couldn't load more tasks right now, try again later" },
        setIsLoading
    });

    useOnPageLoad(
        !loading && user.isAuthenticated
            ? asyncWithFeedback({
                  action: () => Promise.all(TASK_TYPES.map(type => TasksService.getPage(type))),
                  postAction: allRawTasks => {
                      if (allRawTasks === undefined) return;
                      const [Mine, Others, Done] = allRawTasks.map(({ data, lastEvaluatedKey }) => {
                          const items = mapToListItem(data);
                          return {
                              items,
                              filteredItems: items,
                              searchText: '',
                              lastEvaluatedKey
                          };
                      });
                      setTasksState({
                          Mine,
                          Others,
                          Done
                      });
                  },
                  message: { error: "We couldn't load your tasks, maybe try again later" },
                  setIsLoading
              })
            : _.noop,
        props.navigation,
        [loading, user.isAuthenticated, user.token]
    );

    const tabsConfig = useMemo<TabConfig<TaskTabLabels>[]>(
        () =>
            TASK_TYPES.map(label => {
                const { filteredItems, lastEvaluatedKey } = tasksState[label];
                return {
                    label,
                    items: filteredItems,
                    showLoadMore: !!lastEvaluatedKey
                };
            }),
        [tasksState]
    );

    return (
        <TabScreen
            isLoading={isLoading}
            onItemTap={GUID => props.navigation.navigate('Task details', { GUID })}
            tabs={tabsConfig}
            emptyState={EMPTY_STATE_CONFIG}
            floatingButton={{ label: 'Add new task', handler: handleAddNew }}
            searchBar={{
                name: 'search-tasks',
                placeholder: 'Search tasks',
                onChangeText: handleSearch,
                value: tasksState[activeTab].searchText
            }}
            onLoadMore={handleLoadMore}
            onTabChange={setActiveTab}
        />
    );
};
