import styled from 'styled-components/native';
import { StyledHeadingProps } from './types';
import { isEmbedded } from '@shared/helpers';

export const PageHeading = styled.Text<StyledHeadingProps>`
    font-size: 24px;
    font-weight: bold;
    color: ${({ variant = 'black' }) => (variant === 'white' ? '#ffffff' : '#000000')};
    margin: 48px 8px 16px 8px;
    font-family: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif" !important;
    ${isEmbedded() && 'display: flex; justify-content: space-between'}
`;

export const SectionHeading = styled.Text<StyledHeadingProps>`
    font-size: 14px;
    font-weight: bold;
    color: ${({ variant = 'black' }) => (variant === 'white' ? '#ffffff' : '#000000')};
    margin: 8px;
    font-family: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif" !important;
`;
