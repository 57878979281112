import React, { useEffect, useState } from 'react';
import { Error, InputContainer, Label, Buffer } from '../shared/styles';
import { DateFieldProps } from './types';
import { DatePicker } from './DatePicker/DatePicker';
import _ from 'lodash';

export const DateField = ({ onChange, testID, ...props }: DateFieldProps) => {
    const [hasErrors, setHasErrors] = useState<boolean>(false);

    useEffect(() => {
        setHasErrors(!!(props.touched && props.errors));
    }, [props.errors, props.touched]);

    return (
        <InputContainer>
            <Label>{`${props.label} ${props.required ? '' : '(optional)'}`}</Label>
            <DatePicker
                testID={`${testID}_DatePicker`}
                hasErrors={hasErrors}
                {...props}
                onChange={_.flow(x => x?.valueOf(), onChange)}
            />
            {hasErrors ? <Error>{props.errors}</Error> : <Buffer />}
        </InputContainer>
    );
};
