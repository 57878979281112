import { ForgotPassword } from './screens/ForgotPassword';
import { ForgotPasswordSuccess } from './screens/ForgotPasswordSuccess';
import { ModuleScreen } from '@global/types';
import { Login } from './screens/Login';

export const credentialScreens: ModuleScreen = {
    screens: [
        {
            name: 'Login',
            component: Login,
            type: 'header',
            route: 'login',
            isEntryPoint: true
        },
        {
            name: 'Forgot password',
            component: ForgotPassword,
            type: 'header',
            route: 'forgot-password',
            isEntryPoint: true
        },
        {
            name: 'Forgot password success',
            component: ForgotPasswordSuccess,
            type: 'headerless',
            route: 'forgot-password/success'
        }
    ],
    moduleName: 'credentialGroup'
};

export type CredentialModuleParams = {
    Login: undefined;
    'Forgot password': undefined;
    'Forgot password success': { email: string };
};
