import styled from 'styled-components/native';
import { getInputBackgroundColor, getInputBorderColor } from '../util';
import { StyledInputProps } from './types';

export const Input = styled.TextInput<StyledInputProps>`
    border: solid 2px ${({ hasErrors, isFocused, disabled }) => getInputBorderColor(hasErrors, isFocused, disabled)};
    padding: 16px;
    border-radius: 2px;
    font-size: 16px;
    background-color: ${({ disabled }) => getInputBackgroundColor(disabled)};
`;
