import { createContext, useContext, useCallback, useState, useEffect } from 'react';
import { UserService } from '@global/services/user/user.service';
import { GroupService } from '@global/services/group/group.service';
import { AssociatedGroupUser, AuthenticatedUser, User } from '@shared/types/user';
import { UserActions, UserCachedData, UserState } from './types';
import { useChannelsContext } from '@global/utils/nativescriptHost/ChannelsProvider';
import { logError } from '@global/utils/nativescriptHost/helper';
import { userStorage } from '@global/utils/localStorage/user';

import { ProjectSite } from '@shared/types';

/**
 * Context withy default values
 */
const defaultUser = {
    user: { isAuthenticated: false },
    loading: false,
    sites: [] as ProjectSite[],
    groupUsers: [] as User[],
    associatedGroupUsers: [] as AssociatedGroupUser[]
} as const;

const defaultActions = {
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    setToken: () => Promise.resolve(),
    setAuthenticatedUser: (user: User): void => {}
};

export const UserContext = createContext<UserState & UserActions & UserCachedData>({
    ...defaultUser,
    ...defaultActions
});

/**
 * Provides data and methods to pass down to all the components.
 */
export const useUser = (initialValue: Partial<AuthenticatedUser>): UserState & UserActions & UserCachedData => {
    const [user, setUser] = useState<Partial<AuthenticatedUser>>(initialValue);
    const [loading, setLoading] = useState<boolean>(true);
    const [sites, setSites] = useState<ProjectSite[]>([]);
    const [groupUsers, setGroupUsers] = useState<User[]>([]);
    const [associatedGroupUsers, setAssociatedGroupUsers] = useState<AssociatedGroupUser[]>([]);

    const eventSender = useChannelsContext();

    const saveUserToStore = async (user: AuthenticatedUser) => {
        await userStorage.store(user);
        setUser(user);
    };

    const setAuthenticatedUser = async (user: User) => {
        setLoading(true);
        await saveUserToStore({ ...user, token: user.Token, isAuthenticated: true });
        setLoading(false);
    };

    const login = async (email: string, password: string): Promise<void> => {
        setLoading(true);
        return UserService.login(email, password)
            .then(async user => await setAuthenticatedUser(user))
            .finally(() => setLoading(false));
    };

    const logout = useCallback(async () => await saveUserToStore(defaultUser.user as AuthenticatedUser), []);

    const setToken = async (token?: string): Promise<void> => {
        if (!token) {
            return;
        }
        setLoading(true);
        return UserService.auth(token)
            .then(async user => await setAuthenticatedUser(user))
            .catch(err => {
                logError(err, eventSender);
                logout();
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        // try loading user details from local storage
        !user.isAuthenticated &&
            userStorage
                .get()
                .then(u => u && setUser(u))
                .finally(() => setLoading(false));

        user.isAuthenticated &&
            Promise.all([
                UserService.getSiteList(),
                GroupService.getUsersByGroupGUID(user.Group || ''),
                GroupService.getAssociatedUsersForGroup(user.Group ?? '')
            ]).then(([sites, users, groupUsers]) => {
                setSites(sites);
                setGroupUsers(users);
                setAssociatedGroupUsers(groupUsers);
            });
    }, [user.isAuthenticated, user.token, user.Group]);

    return { user, login, logout, setAuthenticatedUser, setToken, loading, sites, groupUsers, associatedGroupUsers };
};

/**
 * Provides data and methods to pass down to all the components.
 */
export const UserProvider = ({ children }: { children: JSX.Element }) => {
    const state = useUser(defaultUser.user);

    return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};

/**
 * Consumer for User Context
 */
export const useUserContext = () => useContext(UserContext);
