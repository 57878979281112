import _ from 'lodash/fp';
import { TemplateNode } from '../../types';
import { normaliseTemplate as Credential } from './credential';

export const derivedFields = {
    SubForm: _.identity,
    Credential
};

export const isDerivedField = (type: string): type is DerivedFields => type in derivedFields;
export const isPrimitiveField = _.negate(isDerivedField);

// normalise template to SubForm template format
export const normalise = (template: TemplateNode): TemplateNode => {
    const mapper = _.get(template.Type, derivedFields) ?? _.identity;
    return mapper(template);
};

export type DerivedFields = keyof typeof derivedFields;
