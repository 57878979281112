import { FieldProps } from '../Field.type';
import { DateField as DateFieldCtrl } from '../../../DateField';

export const DateField = (props: FieldProps<number>) => {
    const dateProps = {
        onChange: props.onValueChange,
        label: props.title,
        name: props.title
    };
    return <DateFieldCtrl {...{ ...props, ...dateProps }} />;
};
