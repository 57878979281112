import { TextField } from './controls/TextField';
import { DropdownField } from './controls/DropdownField';
import { RadioGroupField } from './controls/RadioGroupField';
import { TextAreaField } from './controls/TextAreaField';
import { DateField } from './controls/DateField';
import { ActionItemField } from './controls/ActionItemField';
import { FileUploadField } from './controls/FileUploadField';
import { derivedFields, DerivedFields } from './derived/common';
import { HiddenField } from './controls/HiddenField';
import _ from 'lodash/fp';

export const primitiveFields = {
    Hidden: HiddenField,
    Text: TextField,
    Dropdown: DropdownField,
    RadioGroup: RadioGroupField,
    TextArea: TextAreaField,
    DateField: DateField,
    DatePicker: ActionItemField,
    ListPicker: ActionItemField,
    FileUpload: FileUploadField
} as const;

export type PrimitiveFieldType = keyof typeof primitiveFields;
export type FieldType = PrimitiveFieldType | DerivedFields;

export const supportedFields = [..._.keys(primitiveFields), ..._.keys(derivedFields)];
