import React from 'react';
import { getInitialsFromName } from './helpers';
import { AvatarProps } from './types';
import * as S from './styles';

export const Avatar = ({ name, ...props }: AvatarProps) => (
    <S.Avatar {...props} testID={`${name}_Avatar`}>
        <S.Text size={props.size}>{getInitialsFromName(name ?? '')}</S.Text>
    </S.Avatar>
);
