import * as Maybe from '@global/utils/fp/maybe';
import { TemplateNode } from '../../types';
import _ from 'lodash/fp';
import * as yup from 'yup';
import { createFieldSchema } from '../../helpers';
import { isPrimitiveField, normalise } from './common';
import { Yup } from '../../validators/core';

export type Subform = {
    fields: Array<TemplateNode>;
    validators: yup.ObjectSchema<any, any, any, any>;
};

export const emptySubForm: Subform = {
    fields: [],
    validators: yup.object()
};

type YupObject = ReturnType<typeof yup.object>;

export const createNestedValidator = (path: string, validator: Yup) => {
    return _.pipe(_.split('.'), _.reverse, ([head, ...tail]) =>
        _.reduce((acc, cur) => yup.object({ [cur]: acc }), yup.object({ [head]: validator }) as YupObject, tail)
    )(path);
};

/** create a subform from a single TemplateNode */

export const createSubForm =
    (parentPath?: string) =>
    ({ ID, ...template }: TemplateNode): Subform => {
        const newID = _.pipe(
            Maybe.of,
            Maybe.map(x => `${x}.${ID}`),
            Maybe.extractWith(ID)
        )(parentPath);

        // primitive field
        if (isPrimitiveField(template.Type))
            return {
                fields: [{ ...template, ID: newID }],
                validators: createNestedValidator(newID, createFieldSchema(template))
            };

        // derived field
        const inheritableFields = ['Readonly', 'Visible', 'Enabled'];
        return _.pipe(
            normalise,
            _.get('Children'),
            Maybe.of,
            Maybe.map(_.pipe(_.map(_.merge(_.pick(inheritableFields, template))), createSubFormMany(newID))),
            Maybe.extractWith(emptySubForm)
        )({ ...template, ID: newID });
    };

export const combine = (f1: Subform, f2: Subform): Subform => ({
    fields: [...f1.fields, ...f2.fields],
    validators: f1.validators.concat(f2.validators)
});

/** create a subform from multiple TemplateNode */
export const createSubFormMany =
    (parentPath?: string) =>
    (templates: Readonly<TemplateNode[]>): Subform =>
        _.pipe(_.map(createSubForm(parentPath)), _.reduce(combine, emptySubForm))(templates);
