import { View } from 'react-native';
import { Paragraph } from '@elements/typography/Paragraph';
import { OrderedListProps } from './types';
import * as S from './styles';

export const OrderedList = (props: OrderedListProps) => (
    <View>
        {props.items
            .map(Item => () => Item)
            .map((Item, index) => (
                <S.Container key={index} testID="OrderedListItem">
                    <Paragraph>{index + 1}. </Paragraph>
                    <Item />
                </S.Container>
            ))}
    </View>
);
