import _ from 'lodash';

export type LowerFirst<S extends string> = S extends `${infer P1}${infer P2}`
    ? `${P1 extends Uppercase<P1> ? Lowercase<P1> : P1}${P2}`
    : S;
export type KeysToLowerFirst<T> = { [P in keyof T as LowerFirst<string & P>]: T[P] };

export type CapitalizeKeys<T> = { [P in keyof T as Capitalize<string & P>]: T[P] };

export const mapKeysToLowerFirst = <T extends object>(obj: T): KeysToLowerFirst<T> =>
    _.mapKeys(obj, (_value, key) => _.lowerFirst(key)) as KeysToLowerFirst<T>;

export const capitaliseKeys = <T extends object>(obj: T): CapitalizeKeys<T> =>
    _.mapKeys(obj, (_value, key) => _.upperFirst(key)) as CapitalizeKeys<T>;
