import { useEffect, useState } from 'react';
import { DynamicForm, TemplateNode } from '@elements/forms/DynamicForm';
import { Button } from '@elements/fundamentals/Button';
import { FooterButtons } from '@elements/layout/FooterButtons';
import { Screen } from '@elements/layout/Screen';
import { Heading } from '@elements/typography/Heading';
import { FtmFormProps } from './types';
import { FTMForm } from '../../shared/types';
import { useUserContext } from '@context/UserProvider';
import { useChannelsContext } from '@global/utils/nativescriptHost/ChannelsProvider';
import { TemplatesService } from '../../services/templates';
import { goBack } from '@UIUtils/navigate';
import { useFormValue } from '@elements/forms/DynamicForm/hooks';
import _ from 'lodash/fp';
import * as Maybe from '@global/utils/fp/maybe';

export const FtmForm = ({ navigation, route: { params } }: FtmFormProps) => {
    const { templateId, formId } = params as any;
    const { loading } = useUserContext();
    const [isLoading, setIsLoading] = useState(true);
    const [title, setTitle] = useState<string>();
    const [formTemplate, setFormTemplate] = useState<Readonly<TemplateNode[]>>([]);
    const eventSender = useChannelsContext();
    const [isValid, setIsValid] = useState(false);
    const [formValueRef, getFormValue] = useFormValue();

    // fetch template and form data (if any)
    useEffect(() => {
        eventSender?.log({ message: 'Parameters', data: { templateId, formId }, type: 'Info' });

        if (!loading && templateId) {
            TemplatesService.get(templateId, formId).then((template: FTMForm<TemplateNode>) => {
                const [section] = template.Sections;
                setTitle(section.Title);
                setFormTemplate(section.Fields);
                setIsLoading(false);
                navigation.setOptions({ title: template.Title });
            });
        }
    }, [loading, templateId]);

    const handleSubmit = _.pipe(
        getFormValue,
        Maybe.map(
            _.pipe(
                _.tap(() => setIsLoading(true)),
                v =>
                    TemplatesService.save(templateId, v)
                        .then(data => {
                            eventSender?.log({ message: 'Payload', data, type: 'Info' });
                            navigation.navigate('Ftm submit success');
                        })
                        .finally(() => setIsLoading(false))
            )
        )
    );

    return (
        <Screen
            isLoading={isLoading}
            footer={
                <FooterButtons>
                    <Button halfSize onPress={goBack(navigation, eventSender)} type="secondary" testID="BackBtn">
                        Cancel
                    </Button>
                    <Button disabled={!isValid} halfSize onPress={handleSubmit} type="primary" testID="SaveBtn">
                        Save
                    </Button>
                </FooterButtons>
            }
        >
            <Heading type="page">{title}</Heading>
            <DynamicForm template={formTemplate} getValue={formValueRef} isValidChange={setIsValid} />
        </Screen>
    );
};
