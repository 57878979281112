import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Avatar } from '@elements/fundamentals/Avatar';
import hazardCoLogo from '@assets/brand/icon.png';
import * as S from './styles';
import { IconProps } from './types';

interface ConditionalWrapperParam {
    condition: boolean;
    wrapper: [React.FC<JSX.Element>, React.FC<JSX.Element>];
    children: JSX.Element;
}

const ConditionalWrapper = ({ condition, wrapper: [wrapper1, wrapper2], children }: ConditionalWrapperParam) =>
    condition ? wrapper1(children) : wrapper2(children);

export const Icon = (props: IconProps) => {
    const getIcon = () => {
        switch (props.iconType) {
            case 'glyphicon':
                return (
                    <MaterialCommunityIcons
                        name={props.icon as any}
                        size={props.iconSize ?? 30}
                        color={props.iconColor ?? '#ffffff'}
                    />
                );
            case 'avatar':
                return <Avatar name={props.icon} size={props.iconSize ?? 36} background="#0091EA" />;
            case 'logo':
                return <S.HazardCoIcon source={hazardCoLogo} />;
        }
    };

    return (
        <ConditionalWrapper
            condition={props.style === 'big'}
            wrapper={[
                children => <S.Icon color={props.color}>{children}</S.Icon>,
                children => <S.SmallIcon>{children}</S.SmallIcon>
            ]}
        >
            {getIcon()}
        </ConditionalWrapper>
    );
};
