import styled from 'styled-components/native';

export const Wrapper = styled.View`
    margin-top: 16px;
`;

export const Container = styled.View`
    border: solid transparent 0.5px;
    border-top-color: #bcbcbc;
`;
