import { TemplateNode } from './types';
import _ from 'lodash/fp';
import { fieldAccessKey, getDefaultValue } from './helpers';
import { createTrigger, Trigger } from './condition/engine';
import * as yup from 'yup';
import { createSubFormMany } from './fields/derived/subform';

type InitAction = {
    type: 'INIT';
    payload: Readonly<TemplateNode[]>;
};

type UpdateAction = {
    type: 'UPDATE';
    template: Record<string, Partial<TemplateNode>>;
};

type Action = InitAction | UpdateAction;

export type State = {
    evaluatedTemplate: Record<string, TemplateNode>;
    originalTemplate: Record<string, TemplateNode>;
    trigger: Trigger<any>;
    validators?: yup.ObjectSchema<any, any, any, any>;
    defaultValue?: any;
};

const mapTemplate = (t: Readonly<TemplateNode[]>) =>
    _.pipe(
        _.map((x: TemplateNode) => [fieldAccessKey(x), x]),
        _.fromPairs
    )(t);

// action handlers
export const init = (template: readonly TemplateNode[]): State => {
    const { validators, fields } = createSubFormMany()(template);

    const originalTemplate = mapTemplate(fields);
    const trigger = _.pipe(
        _.map((t: TemplateNode) => [fieldAccessKey(t), t.Conditions ?? []]),
        _.fromPairs,
        createTrigger
    )(template);

    return {
        evaluatedTemplate: originalTemplate,
        originalTemplate,
        trigger,
        validators,
        defaultValue: getDefaultValue(fields)
    };
};

export const update = (state: State, template: Record<string, Partial<TemplateNode> | undefined>): State => {
    const updateKeys = _.keys(template);

    const evaluatedTemplate = {
        ...state.evaluatedTemplate,
        ..._.mergeAll([_.pick(updateKeys, state.originalTemplate), template])
    };

    return {
        ...state,
        evaluatedTemplate
    };
};

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'INIT':
            return init(action.payload);
        case 'UPDATE':
            return update(state, action.template);
        default:
            return state;
    }
};
