import React from 'react';
import _ from 'lodash';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { ListItemProps } from './types';
import * as S from './styles';

export const ListItem = (props: ListItemProps) => {
    const { showActionSheetWithOptions } = useActionSheet();

    const handleOptionsPress = () => {
        const options = [...props.actions, { handler: _.noop, label: 'Cancel' }];
        const cancelIndex = options.length - 1;

        showActionSheetWithOptions(
            {
                options: options.map(({ label }) => label),
                message: 'Actions',
                cancelButtonIndex: cancelIndex,
                destructiveButtonIndex: cancelIndex
            },
            selectedIndex => {
                !_.isUndefined(selectedIndex) && props.actions[selectedIndex]?.handler(props.GUID);
            }
        );
    };

    return (
        <S.Item onPress={() => props.onTap?.(props.GUID)} testID={`${props.title}_ListItem`}>
            <S.Icon>
                <Icon name={props.icon as any} size={24} color={props.accentColor} />
            </S.Icon>
            <S.MainContainer>
                <S.Title numberOfLines={1}>{props.title}</S.Title>
                {props.bodyItems.filter(Boolean).map((item, index) => (
                    <S.BodyText key={`${props.GUID}-bodyItem-${index}`} numberOfLines={1}>
                        {item}
                    </S.BodyText>
                ))}
            </S.MainContainer>
            <S.OptionButtonContainer>
                <S.OptionsButton onPress={handleOptionsPress} testID={`${props.title}_ActionBtn`}>
                    <Icon name="dots-horizontal" color="#8a8a8e" size={30} />
                </S.OptionsButton>
            </S.OptionButtonContainer>
        </S.Item>
    );
};
