import _ from 'lodash';
import { ListItemConfig } from '@elements/collections/ListItem';
import { ServerlessAPI } from '@global/services/api.config';
import {
    Activity,
    TaskDTO,
    TaskFormModel,
    TaskNoteDTO,
    TaskPriorityStyles,
    TaskStatuses,
    UpdateTaskDTO
} from '../../shared/types';
import { GetTaskPayload, TaskIconStyles, TaskTabLabels } from './types';
import { Page } from '@global/services/types';
import { FeedItem, FeedItemContent } from '@elements/collections/ActivityFeedItem';
import { safeFormatMediumDate, UserLocale } from '@global/utils/helpers/date';
import { RelativeDate } from '@elements/fundamentals/RelativeDate';
import { Changes, URI } from '@shared/types';

const mapToDTO = (value: TaskFormModel, GUID?: string): TaskDTO => ({
    Title: value.Title,
    Priority: value.Priority,
    Status: value.Status,
    Assignee: value['Assigned to'],
    Description: value.Description,
    ProjectGUID: value['Site location'],
    AssigneeName: '', // This display value gets added separately to the standard DTO mapping
    GUID,
    DueDate: value['Due date'],
    Images: value.Photos
});

const mapToListItem = (tasks: TaskDTO[]): ListItemConfig[] =>
    tasks.map(task => ({
        GUID: task.GUID!,
        title: task.Title,
        actions: [],
        bodyItems: [
            `${task.Priority} - ${task.AssigneeName}`,
            task.Address,
            task.DueDate ? <RelativeDate date={task.DueDate} prefix="Due" /> : undefined
        ],
        ...patchListItemIcon(task)
    }));

export const patchListItemIcon = ({ Status, Priority }: TaskDTO): TaskIconStyles =>
    _.cond<TaskStatuses, TaskIconStyles>([
        [_.matches('Done'), _.constant({ icon: 'check-circle-outline', accentColor: '#43A047' })],
        [_.matches("Won't do"), _.constant({ icon: 'close-circle-outline', accentColor: '#E53935' })],
        [
            _.stubTrue,
            _.constant({ icon: TaskPriorityStyles[Priority].icon, accentColor: TaskPriorityStyles[Priority].color })
        ]
    ])(Status);

const mapToFormModel = ({
    Assignee,
    ProjectGUID,
    Description,
    Priority,
    Status,
    Title,
    DueDate,
    Images
}: TaskDTO): TaskFormModel => ({
    'Assigned to': Assignee ?? 'Unassigned',
    'Site location': ProjectGUID ?? '',
    Description,
    Priority,
    Status,
    Title,
    'Due date': DueDate,
    Photos: Images
});

const getTaskByGUID = (GUID: string): Promise<GetTaskPayload> =>
    ServerlessAPI.get<GetTaskPayload>(`/task/${GUID}`).then(({ data }) => data);

const create = (task: TaskDTO): Promise<TaskDTO> => ServerlessAPI.post<TaskDTO>('/task', task).then(x => x.data);

const update = ({ Images, ...task }: UpdateTaskDTO): Promise<TaskDTO> => {
    const patchedTask = { ...task, Images: Images?.map(replaceURLWithKey) };
    return ServerlessAPI.patch<TaskDTO>(`/task/${task.GUID}`, patchedTask).then(x => x.data);
};

const replaceURLWithKey = (url: URI) =>
    url.includes('https') ? url.substring(url.lastIndexOf('/') + 1, url.indexOf('?')) : url;

const addNote = (task: TaskNoteDTO): Promise<TaskDTO> =>
    ServerlessAPI.patch<TaskDTO>('/task/note', task).then(x => x.data);

const getPage = async (type: TaskTabLabels, startKey?: Partial<TaskDTO>): Promise<Page<TaskDTO>> =>
    ServerlessAPI.post<Page<TaskDTO>>(`/task/${type.toLowerCase()}`, { startKey }).then(x => x.data);

const deleteTask = (GUID: string) => ServerlessAPI.delete<TaskDTO>(`/task/${GUID}`).then(x => x.data);

const getActivitiesForTask = (GUID: string, startKey?: Partial<Activity>): Promise<Page<Activity>> =>
    ServerlessAPI.post<Page<Activity>>('/task/activities', { GUID, startKey }).then(x => x.data);

const mapActivitiesToFeedItem = (user: UserLocale, activities: Activity[]): FeedItem[] =>
    activities.map(item => ({
        creator: item.ModifiedBy,
        timestamp: item.ModifiedAt,
        content: formatChangesForFeedItem(user, item.Changes),
        note: item.Note,
        images: item.Attachments
    }));

const omittableChanges: Readonly<string[]> = [
    'GUID',
    'ProjectGUID',
    'LastModifiedBy',
    'CreatedAt',
    'LastModifiedAt',
    'Assignee',
    'Images'
];

const formatKey = _.cond([
    [_.matches('AssigneeName'), _.constant('Assignee')],
    [_.matches('Address'), _.constant('Site location')],
    [_.matches('DueDate'), _.constant('Due date')],
    [_.stubTrue, _.identity]
]);

export const formatChangesForFeedItem = (user: UserLocale, changes: Changes<TaskDTO>): FeedItemContent[] => {
    const formatters = (key: string, value: unknown) =>
        _.cond([
            [_.matches('DueDate'), () => safeFormatMediumDate(user)(value)],
            [_.stubTrue, _.constant(value)]
        ])(key);

    return _(changes)
        .mapValues((field, key) => _.mapValues(field, _.partial(formatters, key)))
        .toPairs()
        .reject(([key]) => omittableChanges.includes(key))
        .map(([property, change]) => ({
            property: formatKey(property),
            previous: change?.before as string,
            updated: change?.after as string
        }))
        .orderBy(({ property }) => property, 'desc')
        .value();
};
export const TasksService = {
    mapToDTO,
    mapToFormModel,
    mapToListItem,
    getTaskByGUID,
    update,
    addNote,
    create,
    getPage,
    deleteTask,
    getActivitiesForTask,
    mapActivitiesToFeedItem
};
