import styled from 'styled-components/native';

export const Container = styled.View`
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const Icon = styled.Image`
    height: 192px;
    width: 192px;
`;
