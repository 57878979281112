import styled from 'styled-components/native';
import { StyledSearchBarProps } from './types';

export const Container = styled.View<StyledSearchBarProps>`
    background-color: ${({ isHeader }) => (isHeader ? '#111111' : '#ffffff')};
    padding: 16px;
    position: relative;
`;

export const Input = styled.TextInput<StyledSearchBarProps>`
    font-size: 16px;
    padding: 8px;
    padding-left: 36px;
    background-color: ${({ isHeader }) => (isHeader ? '#353535' : '#efeff0')};
    border-radius: 4px;
    color: ${({ isHeader }) => (isHeader ? '#ffffff' : '#111111')};
`;

export const Icon = styled.View`
    position: absolute;
    left: 24px;
    top: 20px;
    z-index: 2;
`;
