import { PartialRecord } from '@global/utils/type';
import _ from 'lodash/fp';

export const effectTranslator = {
    Visible: { Visible: true },
    Invisible: { Visible: false },
    Enabled: { Enabled: true },
    Disabled: { Enabled: false }
};

export type Effect = keyof typeof effectTranslator;
export type VisualChange = typeof effectTranslator[Effect];

export const translateEffects = <T extends object>(
    effects: PartialRecord<keyof T, Effect[]>
): PartialRecord<keyof T, VisualChange> => {
    return _.pipe(
        _.mapValues(
            _.pipe(
                _.map((x: Effect) => effectTranslator[x]),
                _.mergeAll
            )
        )
    )(effects);
};
