import success from '@assets/backgrounds/feedback-success.png';
import error from '@assets/backgrounds/feedback-error.png';
import info from '@assets/backgrounds/feedback-info.png';
import { FeedbackIcons, FeedbackScreenTypes } from './types';

export const getBackgroundImage = (type: FeedbackScreenTypes) => {
    const map: Record<FeedbackScreenTypes, any> = {
        success,
        info,
        error
    };
    return map[type];
};

export const getIcon = (type: FeedbackScreenTypes): FeedbackIcons => {
    const map: Record<FeedbackScreenTypes, FeedbackIcons> = {
        success: 'check-circle-outline',
        info: 'information-outline',
        error: 'alert-circle-outline'
    };
    return map[type];
};
