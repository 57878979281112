import React from 'react';
import { EmptyState } from '@elements/feedback/EmptyState';
import { ListItem } from '../ListItem';
import { ListViewProps } from './types';
import * as S from './styles';
import { Button } from '@elements/fundamentals/Button';

export const ListView = (props: ListViewProps) =>
    props.items.length ? (
        <S.Container>
            {props.items.map((item, index) => (
                <ListItem {...item} key={index} onTap={props.onTap} />
            ))}
            {props.showLoadMore && (
                <Button type="secondary" onPress={props.onLoadMore} testID="loadMoreBtn">
                    Load more
                </Button>
            )}
        </S.Container>
    ) : props.emptyState ? (
        <EmptyState {...props.emptyState} />
    ) : (
        <></>
    );
