import { Heading } from '@elements/typography/Heading';
import { LoadingScreen } from '@elements/feedback/LoadingScreen';
import { ModalProps } from './types';
import * as S from './styles';

export const Modal = (props: ModalProps) => (
    <S.Modal>
        <S.Container>
            <S.Header>
                <Heading type="page">{props.heading}</Heading>
                <S.Cancel onPress={props.onClose} testID={`${props.testID}CancelBtn`}>
                    <S.Text>Cancel</S.Text>
                </S.Cancel>
            </S.Header>
            <S.Body>{props.children}</S.Body>
            {props.footer}
        </S.Container>
        {props.isLoading && <LoadingScreen />}
    </S.Modal>
);
