import styled from 'styled-components/native';

export const InputContainer = styled.View<{ isHidden?: boolean }>`
    margin: 16px 0;
    width: 95%;
    align-self: center;
    display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
`;

export const Label = styled.Text`
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 8px;
`;

export const Error = styled.Text`
    font-size: 13px;
    color: red;
`;

export const Buffer = styled.View`
    height: 16px;
`;
