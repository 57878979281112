import { User, ProjectSite, AuthenticatedUser, AssociatedGroupUser } from '@shared/types';
import { TaskDTO, TaskFormModel } from '../../shared/types';
import { toTeamAssigneeOptions, toOtherAssigneeOptions, toSiteOptions } from './actionOptions';
import { TemplateNode } from '@elements/forms/DynamicForm';
import { createTaskDetailsTemplate } from '../../components/TaskFormPage';
import fp from 'lodash/fp';
import { TasksService } from '../../services/tasks';
import { events } from './metrics';

export interface State {
    task?: TaskDTO;
    creator?: User;
    user: Partial<AuthenticatedUser>;
    groupName?: string;
    formTemplate: TemplateNode[];
    taskFormModel?: TaskFormModel;
}

export type Action =
    | {
          type: 'LOAD_TASK';
          task: TaskDTO;
          creator: User;
          groupName: string;
          teamMembers: User[];
          nonTeamMembers: AssociatedGroupUser[];
          sites: ProjectSite[];
      }
    | {
          type: 'UPDATE_TASK';
          task: TaskDTO;
      };

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'LOAD_TASK':
            return fp.flow(
                ({ teamMembers, nonTeamMembers, sites }) => [
                    events.actions,
                    {
                        associatedGroupUserOptions: toOtherAssigneeOptions(nonTeamMembers),
                        teamUserOptions: toTeamAssigneeOptions(teamMembers),
                        siteOptions: toSiteOptions(sites)
                    }
                ],
                fp.spread(createTaskDetailsTemplate),
                formTemplate => ({
                    ...state,
                    task: action.task,
                    creator: action.creator,
                    groupName: action.groupName,
                    formTemplate,
                    taskFormModel: TasksService.mapToFormModel(action.task)
                })
            )(action);
        case 'UPDATE_TASK':
            return {
                ...state,
                task: action.task,
                taskFormModel: TasksService.mapToFormModel(action.task)
            };
        default:
            return state;
    }
};
