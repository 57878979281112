import styled from 'styled-components/native';
import { Platform } from 'react-native';

export const Item = styled.TouchableOpacity`
    padding: 16px;
    border: solid transparent 0.5px;
    border-bottom-color: #bcbcbc;
    width: 100%;
    flex-direction: column;
`;

export const Title = styled.Text`
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
`;

export const HeaderContent = styled.View`
    flex-direction: column;
    padding: 0 16px;
`;

export const Subtitle = styled.Text`
    font-size: 14px;
    margin-top: 4px;
    color: #8a8a8e;
    overflow: hidden;
`;

export const Header = styled.View`
    flex-direction: row;
    width: 100%;
`;

export const Content = styled.View`
    display: flex;
    flex-direction: column;
`;

export const Changes = styled.View`
    font-size: 16px;
    margin: 16px 0 8px 0;
    flex-direction: column;
`;

export const Property = styled.Text`
    display: ${() => (Platform.OS === 'web' ? 'inline' : 'flex')};
    font-weight: bold;
`;

// Flex display seems to work a bit differently between native and web, so for the content we
// need to use 'display: block' to get the right wrapping on web. This isn't required for native
// though, so we can just use a normal flex display.
export const Text = styled.Text<{ column?: boolean }>`
    display: ${({ column }) => (Platform.OS === 'web' ? (column ? 'flex' : 'block') : 'flex')};
    flex-direction: ${({ column }) => (column ? 'column' : 'row')};
    margin-bottom: 8px;
`;
