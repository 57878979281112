import { AssociatedGroupUser, User } from '@shared/types';
import { Group } from '@shared/types';
import { ServerlessAPI } from '../api.config';

const getUsersByGroupGUID = (GUID: string): Promise<User[]> =>
    ServerlessAPI.get<Group>(`/group/${GUID}`).then(({ data }) => data.Users);

const getAssociatedUsersForGroup = (GUID: string): Promise<AssociatedGroupUser[]> =>
    ServerlessAPI.get<AssociatedGroupUser[]>(`/scan/group/${GUID}`).then(({ data }) => data);

export const GroupService = { getUsersByGroupGUID, getAssociatedUsersForGroup };
