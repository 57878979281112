import AsyncStorage from '@react-native-async-storage/async-storage';

export type StoreItemMeta<T = unknown> = {
    type?: T;
    key: string;
    isPlainText: boolean;
};

export const storeData = async <T>(meta: StoreItemMeta<T>, value: T): Promise<void> => {
    const jsonValue = meta.isPlainText ? (value as unknown as string) : JSON.stringify(value);
    await AsyncStorage.setItem(meta.key, jsonValue);
};

export const getData = async <T>(meta: StoreItemMeta<T>): Promise<T | null> => {
    const jsonValue = await AsyncStorage.getItem(meta.key);

    if (jsonValue === null) return null;

    if (meta.isPlainText) return jsonValue as unknown as T;

    return JSON.parse(jsonValue);
};
