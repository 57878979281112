// screen name
export const screenName = 'Task Details';

// events
export const events = Object.freeze({
    actions: {
        status: `${screenName} - Action - Status` as const,
        siteLocation: `${screenName} - Action - Site location` as const,
        assignee: `${screenName} - Action - Assignee` as const,
        priority: `${screenName} - Action - Priority` as const,
        dueDate: `${screenName} - Action - Due Date` as const
    },
    addNote: `${screenName} - Add Note`,
    options: {
        edit: `${screenName} - Option - Edit` as const
    }
});

export const metrics = Object.freeze({
    screenName,
    events
});
