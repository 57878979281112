import { ModuleScreen } from '@global/types';
import { FtmForm } from './screens/FtmForm';
import { FtmFormSuccess } from './screens/FtmFormSuccess';

export type FtmModuleParams = {
    Form: undefined;
    'Ftm submit success': undefined;
};
export const ftmScreens: ModuleScreen = {
    screens: [
        {
            name: ' ',
            component: FtmForm,
            route: 'ftm',
            type: 'header'
        },
        {
            name: 'Ftm submit success',
            component: FtmFormSuccess,
            type: 'headerless',
            route: 'ftm/success'
        }
    ],
    moduleName: 'ftmGroup'
};
