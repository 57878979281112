import styled from 'styled-components/native';
import { StyledParagraphProps } from './types';

export const paragraphColors = {
    muted: '#8a8a8e',
    error: '#FF0000'
};
export const Paragraph = styled.Text<StyledParagraphProps>`
    font-size: 16px;
    margin: 0 8px ${({ noMargin }) => (noMargin ? '0' : '16px')} 8px;
    text-align: ${({ centered }) => (centered ? 'center' : 'left')};
    color: ${({ color }) => (color ? paragraphColors[color] : '#000000')};
    font-family: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif" !important;
`;
