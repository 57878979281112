import styled from 'styled-components/native';
import web from 'styled-components';

export const InvisibleInput = web.input`
    visibility: hidden;
`;

const emptyContainerStyles = `
    padding: 16px;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    height: 128px;
`;

export const EmptyContainer = styled.TouchableOpacity`
    ${emptyContainerStyles}
    background: #f7fcfe;
    border: dashed 1px #d4e7f3;
`;

export const DisabledEmptyContainer = styled.View`
    ${emptyContainerStyles}
    background-color: #f5f5f5;
    border: dashed 1px #bcbcbc;
`;

export const Placeholder = styled.Text`
    font-weight: bold;
`;

export const Limit = styled.Text`
    font-size: 10px;
    margin-top: 8px;
`;
