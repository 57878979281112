import { API_LEVEL, WebViewEvent, WebViewEventKey, webViewEventKey } from './api';
import { version } from '../../../../package.json';
import _ from 'lodash';

type EventInput<K extends WebViewEventKey> = WebViewEvent[K]['input'];
type EventOutput<K extends WebViewEventKey> = WebViewEvent[K]['output'];
type Payload<T> = {
    seqId: number;
    apiLevel: number;
    payload: T;
};

export interface GenericWebViewInterface<Event = string, Input = any, Output = any> {
    emit: (eventName: Event, data: Input) => void;
    on: (eventName: Event, callback: (arg: Output) => void) => void;
}

type WebViewInterface<K extends WebViewEventKey> = GenericWebViewInterface<
    K,
    Payload<EventInput<K>>,
    Payload<EventOutput<K>>
>;

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const createChannel = <K extends WebViewEventKey>(wvInterface: WebViewInterface<K>, key: K) => {
    let seqId = 0;
    const resolvers: Map<number, (arg: EventOutput<K>) => void> = new Map();

    wvInterface.on(key, async arg => {
        // to prevent the callback is called too soon, before the callback is registered to Map object
        let tryTimes = 10;
        while (!resolvers.get(arg.seqId) && tryTimes--) {
            await sleep(500);
        }
        resolvers.get(arg.seqId)?.(arg.payload);
        resolvers.delete(arg.seqId);
    });

    return (payload: EventInput<K>): Promise<EventOutput<K>> => {
        wvInterface.emit(key, { seqId, payload, apiLevel: API_LEVEL });
        const promise = new Promise<EventOutput<K>>(resolve => resolvers.set(seqId, resolve));
        seqId++;

        return promise;
    };
};

export type Channels = {
    [K in WebViewEventKey]: (payload: EventInput<K>) => Promise<EventOutput<K>>;
};

// only to be used by unit test and `ChannelsProvider`
export const createEventSender = (wvInterface: GenericWebViewInterface) => {
    const channels = _(webViewEventKey)
        .map(k => [k, createChannel(wvInterface, k)])
        .fromPairs()
        .value() as Channels;
    channels.handshake({ appVersion: version });

    return channels;
};
