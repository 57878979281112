export const screenName = 'Task Update';
export const events = Object.freeze({
    cancel: 'Task Update - Cancel',
    mainAction: 'Task Update - Save'
});

export const metrics = Object.freeze({
    screenName,
    events
});
