import fp from 'lodash/fp';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Tabs, RenderChildProps } from '@elements/layout/Tabs';
import { SearchBar } from '@elements/layout/SearchBar';
import { TemplateTabConfig } from '../DynamicForm';
import { Option } from '../ActionItem';
import { hasTabbedOptions } from '../util';
import { RadioGroupProps } from './types';
import { Label } from '../shared/styles';
import { RadioButtons } from './components';
import * as S from './styles';

export const RadioGroup = ({
    testID,
    iconType,
    selected,
    options,
    onChange,
    disabled,
    required,
    searchable,
    label
}: RadioGroupProps) => {
    const [selectedOption, setSelectedOption] = useState<string | undefined>(selected);
    const [tabSearch, setTabSearch] = useState<string[]>([]);
    const [tabIndex, setTabIndex] = useState<number>(0);

    const setInitialTab = useCallback(() => {
        if (!hasTabbedOptions(options)) {
            return;
        }

        fp.flow(
            fp.find({ Options: [{ value: selected }] }),
            fp.defaultTo(fp.first(options)),
            fp.indexOf(fp.__, options),
            fp.concat(0),
            fp.max,
            fp.defaultTo(0),
            setTabIndex
        )(options);
    }, [selected, options]);

    const setInitialTabSearch = useCallback(() => {
        // Prevent resetting the search value once a new `selected` prop comes in
        // if there is already search text
        if (tabSearch.some(value => value)) return;

        setTabSearch(hasTabbedOptions(options) ? options.map(() => '') : ['']);
    }, [options, tabSearch]);

    const handleTabChange = useCallback(
        (tabName: string) =>
            setTabIndex(fp.findIndex<TemplateTabConfig>({ Label: tabName })(options as TemplateTabConfig[])),
        [options]
    );

    const applyFilter = useCallback(
        (opts: Option[]) =>
            opts.filter(({ label }) => label.toLowerCase().includes(tabSearch[tabIndex]?.toLowerCase())),
        [tabIndex, tabSearch]
    );

    const filteredTabConfig: RadioGroupProps['options'] = useMemo(
        () =>
            hasTabbedOptions(options)
                ? options.map(tab => ({
                      ...tab,
                      Options: applyFilter(tab.Options)
                  }))
                : applyFilter(options),
        [applyFilter, options]
    );

    useEffect(() => {
        setSelectedOption(selected);
        setInitialTab();
        setInitialTabSearch();
    }, [selected]);

    const onPress = useCallback(
        (id: string) => {
            setSelectedOption(id);
            onChange?.(id);
        },
        [onChange]
    );

    const commonProps = useMemo(
        () => ({
            selected: selectedOption,
            onChange: onPress,
            disabled,
            iconType,
            testID
        }),
        [disabled, iconType, onPress, selectedOption, testID]
    );

    const renderScene = useCallback(
        ({ route }: RenderChildProps) => {
            if (!hasTabbedOptions(options)) return <Fragment />;

            const tab = fp.find({ Label: route.key }, filteredTabConfig as TemplateTabConfig[]);
            if (!tab) return <Fragment />;

            return (
                <S.Container>
                    <RadioButtons options={tab.Options} {...commonProps} />
                </S.Container>
            );
        },
        [commonProps, filteredTabConfig, options]
    );

    const handleSearch = useCallback(
        (text: string) => setTabSearch(tabSearch.map((value, index) => (index === tabIndex ? text : value))),
        [tabIndex, tabSearch]
    );

    return (
        <Fragment>
            {searchable && (
                <SearchBar
                    placeholder="Search people or companies"
                    name="search"
                    onChangeText={handleSearch}
                    value={tabSearch[tabIndex]}
                />
            )}
            {hasTabbedOptions(options) ? (
                <Tabs
                    tabs={(filteredTabConfig as TemplateTabConfig[]).map(({ Label }) => ({ label: Label, items: [] }))}
                    onTabChange={handleTabChange}
                    startingIndex={tabIndex}
                    renderChild={renderScene}
                />
            ) : (
                <S.Container>
                    {!!label && <Label>{`${label} ${required ? '' : '(optional)'}`}</Label>}
                    <RadioButtons options={filteredTabConfig as Option[]} {...commonProps} />
                </S.Container>
            )}
        </Fragment>
    );
};
