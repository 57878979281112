import { useLayoutEffect } from 'react';
import { ScreenProps } from '@global/types';
import { MoreButton } from '@elements/layout/HeaderButtons';
import { HeaderButtonProps } from '@react-navigation/native-stack/lib/typescript/src/types';

export const useHeaderRightButton = (navigation: ScreenProps['navigation'], onPress: () => void) => {
    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: (headerProps: HeaderButtonProps) => (
                <MoreButton onPress={onPress} color={headerProps.tintColor ?? ''} />
            )
        });
    }, [navigation, onPress]);
};
