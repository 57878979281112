import * as yup from 'yup';
import { Validator } from './type';

export type YupArray = ReturnType<typeof yup.array>;
export const arrayValidator: Validator<YupArray> = {
    supported: {
        Required:
            ({ Title }, isRequired) =>
            v =>
                isRequired ? v.required(`${Title} is required`) : v,
        MaxLength: (_n, max) => v => v.max(max),
        MinLength: (_n, min) => v => v.min(min)
    },
    of: yup.array
};
