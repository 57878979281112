import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { RootSiblingParent as ToastProvider } from 'react-native-root-siblings';
import { AuthContainer } from '@shared/components/AuthContainer/AuthContainer';
import { Interceptor } from '@shared/components/Interceptor/Interceptor';
import { environment, debug } from './src/environment';
import { AppRoutes } from './AppRoutes';
import { UserProvider } from './src/context/UserProvider';
import { BackgroundActivityProvider } from '@context/BackgroundActivityProvider';
import { appLinking } from '@global/routing';
import { NavigationContainer } from '@react-navigation/native';
import { ChannelsProvider } from '@global/utils/nativescriptHost/ChannelsProvider';
import { createBrowserHistory } from 'history';
import { Platform, LogBox } from 'react-native';
import { CustomErrorBoundary } from '@elements/layout/ErrorBoundary/CustomErrorBoundary';
import en from 'javascript-time-ago/locale/en';
import TimeAgo from 'javascript-time-ago';

// Initialise relative date formatting
TimeAgo.addDefaultLocale(en);

// turn off logbox for non-dev env
!debug && LogBox.ignoreAllLogs();

// for web only, to normalize the url such that the specific screen is picked up by Navigation
if (Platform.OS === 'web') {
    const history = createBrowserHistory();
    const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
    if (path) {
        history.replace(path);
    }
}

export const App = () => {
    console.log('Environment:', { environment, debug });

    return (
        <NavigationContainer linking={appLinking}>
            <ChannelsProvider>
                <CustomErrorBoundary>
                    <UserProvider>
                        <BackgroundActivityProvider>
                            <ToastProvider>
                                <ActionSheetProvider>
                                    <AuthContainer>
                                        <Interceptor>
                                            <AppRoutes />
                                        </Interceptor>
                                    </AuthContainer>
                                </ActionSheetProvider>
                            </ToastProvider>
                        </BackgroundActivityProvider>
                    </UserProvider>
                </CustomErrorBoundary>
            </ChannelsProvider>
        </NavigationContainer>
    );
};
