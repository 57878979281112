import * as yup from 'yup';
import { Validator } from './type';

export type YupNumber = ReturnType<typeof yup.number>;
export const numberValidator: Validator<YupNumber> = {
    supported: {
        Required:
            ({ Title }, isRequired) =>
            v =>
                isRequired ? v.required(`${Title} is required`) : v
    },
    of: yup.number
};
