import { FormikProps } from 'formik';
import { ReactElement } from 'react';
import _ from 'lodash';
import { FieldProps } from './Field.type';
import { PrimitiveFieldType, primitiveFields } from './supportedFields';
import { ActionItemSubmission } from '@elements/forms/ActionItem';
import { fieldAccessKey } from '../helpers';

type ExcludeFields =
    | 'errors'
    | 'touched'
    | 'required'
    | 'testID'
    | 'onBlur'
    | 'onValueChange'
    | 'onModalValueChanged'
    | 'disabled';

type DFFieldProps = Omit<FieldProps, ExcludeFields> & { enabled?: boolean };

export function Field<T extends Record<string, unknown> = Record<string, unknown>>({
    formControls,
    onValueChange,
    onModalValueChanged,
    ...props
}: DFFieldProps & {
    formControls: FormikProps<T>;
    onValueChange: (fieldAccessKey: string, changedValue: unknown) => Promise<void>;
    onModalValueChanged: (
        fieldAccessKey: string,
        core: ActionItemSubmission,
        children?: ActionItemSubmission[]
    ) => void;
}): ReactElement | null {
    const valueAccessKey = fieldAccessKey(props);
    const common = {
        errors: _.get(formControls.errors, valueAccessKey) as string | string[] | undefined,
        touched: _.get(formControls.touched, valueAccessKey) as boolean | undefined,
        required: props.validators?.Required,
        testID: `${props.title}Field`,
        onBlur: formControls.handleBlur(valueAccessKey),
        onValueChange: _.partial(onValueChange, valueAccessKey),
        onModalValueChanged: _.partial(onModalValueChanged, valueAccessKey),
        value: _.get(formControls.values, valueAccessKey),
        disabled: !(props.enabled ?? true)
    };

    const Component = primitiveFields[props.type as PrimitiveFieldType] ?? null;

    const visible = (props.visible ?? true) || null;

    return Component && visible && <Component {...(props as any)} {...common} />;
}
