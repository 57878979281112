import { ActivityIndicator } from 'react-native';
import * as S from './styles';
import { LoadingScreenProps } from './types';

export const LoadingScreen = (props: LoadingScreenProps) => (
    <S.Wrapper>
        {props.children}
        <ActivityIndicator size="large" color="white" />
    </S.Wrapper>
);
