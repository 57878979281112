import * as yup from 'yup';
import { Validator } from './type';

export type YupString = ReturnType<typeof yup.string>;
export const stringValidator: Validator<YupString> = {
    supported: {
        Required:
            ({ Title }, isRequired) =>
            v =>
                isRequired ? v.required(`${Title} is required`) : v,
        Email:
            ({ Title }) =>
            v =>
                v.email(`${Title} must be a valid email address`),
        MaxLength: (_n, max) => v => v.max(max),
        MinLength: (_n, min) => v => v.min(min),
        Pattern: (_n, regex) => v => v.matches(regex)
    },
    of: yup.string
};
