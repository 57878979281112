import { ImageDimensions } from './types';

export const getImageDimensions = (url?: string): Promise<ImageDimensions> => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve({ height: image.naturalHeight, width: image.naturalWidth });
        image.onerror = () => reject();
        image.src = url ?? '';
    });
};
