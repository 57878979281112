import _ from 'lodash';
import { Fragment, useState } from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { isURI } from '@shared/helpers';
import { CarouselModal } from '../CarouselModal';
import { GalleryProps } from './types';
import * as S from './styles';

export const Gallery = (props: GalleryProps) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(NaN);

    return (
        <Fragment>
            <S.Gallery>
                {props.showAddButton && (
                    <S.Add onPress={props.onAdd} testID={`${props.testID}_addMoreBtn`}>
                        <MaterialCommunityIcons name="plus-circle" size={32} color="#0091ea" />
                    </S.Add>
                )}
                {props.uris.map(({ uri, isLoading, local }, index) => (
                    <S.ImageContainer key={uri} onPress={() => !isLoading && setSelectedIndex(index)}>
                        <S.Image accessibilityRole="image" source={{ uri: local ?? uri }} />
                        {isLoading && <S.Loader testID={`Gallery_loading_${index}`} />}
                        {!props.readonly && !isLoading && (
                            <S.Remove
                                onPress={() => props.onRemove?.(index)}
                                testID={`Gallery_removeImageBtn_${index}`}
                            >
                                <MaterialCommunityIcons name="close-circle" size={24} color="#0091ea" />
                            </S.Remove>
                        )}
                    </S.ImageContainer>
                ))}
            </S.Gallery>
            {!_.isNaN(selectedIndex) && (
                <CarouselModal
                    startingIndex={selectedIndex}
                    onClose={() => setSelectedIndex(NaN)}
                    uris={props.uris.map(({ uri, local }) => (isURI(local) ? local : uri))}
                />
            )}
        </Fragment>
    );
};
