import React from 'react';
import { AuthGuard, Stack } from '@config/navigation';
import { BackButton } from '@elements/layout/HeaderButtons';
import { ScreenMeta, ScreenProps } from '@global/types';
import { NativeStackNavigationOptions } from '@react-navigation/native-stack';

const screenOptions = {
    headerStyle: { backgroundColor: '#111111' },
    headerTintColor: '#ffffff'
};

const getScreenTypeOptions = (type: 'header' | 'headerless', options?: NativeStackNavigationOptions) =>
    ({
        header: ({ navigation }: { navigation: ScreenProps['navigation'] }) => ({
            headerLeft: () => <BackButton {...{ navigation, color: screenOptions.headerTintColor }} />,
            ...options
        }),
        headerless: { headerShown: false }
    }[type]);

export const ScreenGroup = ({ group, key }: { group: ScreenMeta[]; key: string }) => (
    <Stack.Group screenOptions={screenOptions} key={key}>
        {group.map(({ type, isProtected, component, options, ...props }, index) => (
            <Stack.Screen
                key={index}
                component={isProtected ? AuthGuard(component) : component}
                {...props}
                options={getScreenTypeOptions(type, options)}
            />
        ))}
    </Stack.Group>
);
