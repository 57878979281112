import styled from 'styled-components/native';

export const Background = styled.View`
    background: #ffffff;
    flex-grow: 1;
`;

export const Icon = styled.View`
    margin-right: 4px;
    display: flex;
`;

export const ButtonWrapper = styled.View`
    background: #ffffff;
`;
