import styled from 'styled-components/native';

export const Container = styled.View`
    justify-content: center;
    align-items: center;
    margin-top: 64px;
`;

export const Image = styled.Image`
    height: ${({ height }) => height}px;
    width: ${({ width }) => width}px;
`;
