export enum UserRole {
    StandardUser = 20,
    GroupAdmin = 50,
    HazardCoAdmin = 80,
    SystemAdmin = 100
}

export enum Locale {
    AU = 'AU',
    NZ = 'NZ',
    UK = 'UK'
}

export enum AusState {
    VIC = 'VIC',
    NSW = 'NSW',
    QLD = 'QLD',
    WA = 'WA',
    TAS = 'TAS',
    SA = 'SA',
    NT = 'NT',
    ACT = 'ACT'
}

export enum AccessRole {
    SystemAdmin = 'system_admin',
    SuperUser = 'super_user',
    CompanyAdmin = 'company_admin',
    SiteSuperVisor = 'site_supervisor',
    Contractor = 'contractor',
    SoftwareProvider = 'software_provider',
    NoAccess = 'no_access'
}
