import Toast from 'react-native-root-toast';

export interface FeedbackConfig {
    success?: string;
    error: string;
}

interface AsyncWithFeedbackConfig<I extends any[], O, O1> {
    action: (...args: I) => Promise<O>;
    postAction: (arg: O | undefined) => O1;
    onError?: () => void;
    message?: FeedbackConfig;
    setIsLoading?: (val: boolean) => void;
}

export const asyncWithFeedback =
    <I extends any[], O, O1>({
        action,
        postAction,
        onError,
        message,
        setIsLoading
    }: AsyncWithFeedbackConfig<I, O, O1>) =>
    (...args: I): Promise<O1> => {
        const { success, error } = message ?? {};
        setIsLoading?.(true);
        return action(...args)
            .then(result => ({
                message: success ? `✅ ${success}` : '',
                result,
                type: 'success'
            }))
            .catch(x => ({
                message: `❌ ${x?.response?.data?.message ?? error}`,
                type: 'fail',
                result: undefined
            }))
            .then(({ message, result, type }) => {
                setIsLoading?.(false);
                message &&
                    Toast.show(message, {
                        duration: Toast.durations.LONG
                    });
                if (type === 'fail') {
                    if (!onError) throw new Error('async action error');
                    onError();
                }

                return result as O;
            })
            .then(postAction);
    };
