import { getData, storeData, StoreItemMeta } from '@global/utils/localStorage/localStorage';
import { AuthenticatedUser } from '@shared/types/user';
import _ from 'lodash';

export const mode = process.env.Store_User_Locally?.toLocaleLowerCase() === 'true' ? 'local' : 'dummy';

const userStoreMeta: StoreItemMeta<AuthenticatedUser> = {
    key: 'authenticated_user',
    isPlainText: false
};

export const userStorage: {
    store: (user: AuthenticatedUser) => Promise<void>;
    get: () => Promise<null | AuthenticatedUser>;
} = {
    local: {
        store: _.partial(storeData, userStoreMeta),
        get: () => getData(userStoreMeta)
    },
    dummy: {
        store: () => Promise.resolve(),
        get: () => Promise.resolve(null)
    }
}[mode];
