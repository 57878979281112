import styled from 'styled-components/native';

export const Item = styled.TouchableOpacity`
    padding: 16px;
    border: solid transparent 0.5px;
    border-bottom-color: #bcbcbc;
    width: 100%;
    flex-direction: row;
`;

export const MainContainer = styled.View`
    width: 75%;
`;

export const Title = styled.Text`
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
`;

export const BodyText = styled.Text<{ accentColor?: string }>`
    font-size: 14px;
    margin-top: 4px;
    color: ${({ accentColor }) => accentColor ?? '#8a8a8e'};
    overflow: hidden;
`;

export const OptionButtonContainer = styled.View`
    height: 100%;
    margin-left: auto;
`;

export const OptionsButton = styled.TouchableOpacity`
    padding: 0 8px 8px 8px;
`;

export const Icon = styled.View`
    margin-right: 16px;
`;
