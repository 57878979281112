import styled from 'styled-components/native';

export const Gallery = styled.View`
    flex-direction: row;
    flex-wrap: wrap;
`;

export const Add = styled.TouchableOpacity`
    background: #f7fcfe;
    border: dashed 1px #d4e7f3;
    height: 100px;
    width: 100px;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    margin: 8px;
`;

export const ImageContainer = styled.TouchableOpacity`
    position: relative;
    height: 100px;
    width: 100px;
    margin: 8px;
`;

export const Remove = styled.TouchableOpacity`
    position: absolute;
    top: -9px;
    right: -9px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    background-color: #ffffff;
    border: solid 2px #bcbcbc;
`;

export const Image = styled.Image`
    height: 100%;
    width: 100%;
    background: #f7fcfe;
    border: solid 1px #bcbcbc;
`;

export const Loader = styled.ActivityIndicator`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #f7fcfe;
    border: solid 1px #bcbcbc;
`;
