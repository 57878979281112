import { FeedbackScreen } from '@elements/feedback/FeedbackScreen';
import { Heading } from '@elements/typography/Heading';
import { Paragraph } from '@elements/typography/Paragraph';
import { useChannelsContext } from '@global/utils/nativescriptHost/ChannelsProvider';
import { UpdateTaskSuccessProps } from './types';
export const UpdateTaskSuccess = ({ route, navigation }: UpdateTaskSuccessProps) => {
    const { title, location, assignee, isTransient } = route.params;
    const eventSender = useChannelsContext();

    const handleDonePress = () =>
        isTransient ? eventSender?.navigate({ action: 'goBack' }) : navigation.navigate('Tasks');

    return (
        <FeedbackScreen
            title="Task updated"
            type="success"
            subtitle="The task has been updated"
            primaryButton={{
                text: 'Done',
                handler: handleDonePress,
                testID: 'DoneBtn'
            }}
        >
            <Heading type="section">Task details</Heading>
            <Paragraph noMargin>{title}</Paragraph>
            {location && <Paragraph noMargin>{location}</Paragraph>}
            <Paragraph noMargin>Assigned to {assignee}</Paragraph>
        </FeedbackScreen>
    );
};
