import { Platform } from 'react-native';
import { modularizedScreens } from '@global/routing';
import { NativeStackNavigationOptions } from '@react-navigation/native-stack';
import { CardStyleInterpolators, StackNavigationOptions } from '@react-navigation/stack';
import { Stack } from '@config/navigation/Stack/Stack';
import { ScreenGroup } from '@elements/layout/StackScreens/ScreenGroup';
import { Home } from '@global/screens/Home/Home';
import { ModuleScreen } from '@global/types';

type NavigationOptions = StackNavigationOptions | NativeStackNavigationOptions;

export const AppRoutes = () => {
    const screenOptions: NavigationOptions =
        Platform.OS === 'web'
            ? {
                  animationEnabled: true,
                  cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS
              }
            : {};
    const homeScreen: ModuleScreen = {
        moduleName: 'Home',
        screens: [
            {
                name: 'Home',
                component: Home,
                type: 'headerless',
                route: 'home'
            }
        ]
    };
    return (
        <Stack.Navigator {...screenOptions} initialRouteName="Login">
            {[homeScreen, ...modularizedScreens].map(({ screens, moduleName }) =>
                ScreenGroup({ group: screens, key: moduleName })
            )}
        </Stack.Navigator>
    );
};
