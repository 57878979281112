import { useState } from 'react';
import { Screen } from '@elements/layout/Screen';
import { Button } from '@elements/fundamentals/Button';
import { DynamicForm } from '@elements/forms/DynamicForm';
import { FooterButtons } from '@elements/layout/FooterButtons';
import { Paragraph } from '@elements/typography/Paragraph';
import { LoginForm } from './form';
import { LoginProps } from './types';
import { useUserContext } from '@context/UserProvider';
import { useFormValue } from '@elements/forms/DynamicForm/hooks';
import _ from 'lodash/fp';
import * as Maybe from '@global/utils/fp/maybe';

export const Login = ({ navigation }: LoginProps) => {
    const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const { loading, login } = useUserContext();
    const [formValueRef, getFormValue] = useFormValue<Record<'email' | 'password', string>>();

    const handleSubmit = _.pipe(
        getFormValue,
        Maybe.map(({ email, password }) =>
            login(email, password)
                .then(() => navigation.navigate('Home'))
                .catch(() => setIsAuthorized(true))
        )
    );

    return (
        <Screen
            isLoading={loading}
            footer={
                <FooterButtons>
                    <Button type="primary" onPress={() => handleSubmit()} disabled={!isValid} testID="LoginBtn">
                        Login
                    </Button>
                </FooterButtons>
            }
        >
            <DynamicForm template={LoginForm} isValidChange={setIsValid} getValue={formValueRef} />
            {isAuthorized && (
                <Paragraph color="error" centered>
                    Invalid Credentials
                </Paragraph>
            )}
        </Screen>
    );
};
