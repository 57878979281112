import styled from 'styled-components/native';
import { StyledInnerProps, StyledMarkerProps, StyledTextProps } from './types';

const containerStyles = `
    padding: 16px;
    border: solid transparent 0.5px;
    border-bottom-color: #dfdfdf;
`;

export const Pressable = styled.TouchableOpacity`
    ${containerStyles}
`;

export const NotPressable = styled.View`
    ${containerStyles}
`;

export const Content = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const Icon = styled.View`
    margin-right: 8px;
`;

export const Title = styled.Text<StyledTextProps>`
    font-weight: ${({ isHeading }) => (isHeading ? 'bold' : 'normal')};
    font-size: ${({ isHeading }) => (isHeading ? '16px' : '14px')};
    color: ${({ disabled }) => (disabled ? '#bcbcbc' : '#000000')};
`;

export const Marker = styled.View<StyledMarkerProps>`
    height: 28px;
    width: 28px;
    border-radius: 24px;
    border: solid ${({ selected, disabled }) => (disabled ? '#f5f5f5' : selected ? '#0091EA' : '#bcbcbc')};
    border-width: 3px;
    margin-left: auto;
    align-items: center;
    justify-content: center;
`;

export const Inner = styled.View<StyledInnerProps>`
    background: ${({ disabled }) => (disabled ? '#f5f5f5' : '#0091ea')};
    height: 12px;
    width: 12px;
    border-radius: 12px;
`;

export const Subtitle = styled.Text<StyledTextProps>`
    margin-left: 32px;
    color: ${({ disabled }) => (disabled ? '#bcbcbc' : '#000000')};
`;
