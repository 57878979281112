import { useChannelsContext } from '@global/utils/nativescriptHost/ChannelsProvider';
import { useFocusEffect } from '@react-navigation/native';
import { useCallback, useEffect } from 'react';
import { ScreenName, LogEvent } from './types';

export const useLogScreenName = (screenName?: ScreenName) => {
    const eventSender = useChannelsContext();
    useFocusEffect(
        useCallback(() => {
            screenName && eventSender?.tagScreen({ screenName });
        }, [eventSender, screenName])
    );
};

export const useLogEvent = (event: LogEvent) => {
    const eventSender = useChannelsContext();
    useEffect(() => {
        eventSender?.logEvent({ event });
    }, [eventSender, event]);
};
