import _ from 'lodash/fp';

// transform
export const valueTransformer = {
    None: _.identity,
    Length: (x: { length: number }) => x.length
};

export type Transform = keyof typeof valueTransformer;

// operator
export const operatorMap = {
    Equals: (expected: unknown) => (value: unknown) => expected === value,
    NotEquals: (expected: unknown) => (value: unknown) => expected !== value,
    GreaterThan: (expected: number) => (value: number) => value > expected,
    GreaterThanOrEquals: (expected: number) => (value: number) => value >= expected,
    LesserThan: (expected: number) => (value: number) => value < expected,
    LesserThanOrEquals: (expected: number) => (value: number) => value <= expected,
    Exists:
        <T>(expected: T[]) =>
        (value: T) =>
            expected.includes(value)
};

export type Operator = keyof typeof operatorMap;

export const createPredicate =
    (propKey: string, operator: Operator, expectedValue: unknown, transform: Transform = 'None') =>
    <T extends object>(value: T) => {
        return _.pipe(_.prop(propKey), valueTransformer[transform], operatorMap[operator](expectedValue as any))(value);
    };
