import _ from 'lodash/fp';

export const nothing = {
    tag: 'nothing'
} as const;

export type Nothing = typeof nothing;

export type Just<T = any> = {
    tag: 'just';
    value: T;
};

export type Nil = undefined | null;

export type Maybe<T = any> = Nothing | Just<T>;

const createNothing = <T>(): Maybe<T> => nothing;

const createJust = <T>(value: T): Maybe<T> => ({
    tag: 'just',
    value
});

const isTag = (tag: Maybe['tag']) => _.matches({ tag });
const isJust = <T>(maybe: Maybe<T>): maybe is Just<T> => isTag('just')(maybe);
const isNothing = <T>(maybe: Maybe<T>): maybe is Nothing => isTag('nothing')(maybe);

export const of: <T>(value: T | Nil) => Maybe<T> = _.cond([
    [_.isNil, createNothing],
    [_.T, createJust]
]);

/** Extract underlying value, and resolve as `defaultValue` if Nothing */
export const extractWith =
    <T, R = T>(defaultValue: R) =>
    (maybe: Maybe<T>): T | R => {
        if (isJust(maybe)) return maybe.value;

        return defaultValue;
    };

/** Extract underlying value, and resolve as undefined if Nothing */
export const extract = extractWith(undefined);

export const map =
    <T, R>(mapper: (v: T) => R) =>
    (maybe: Maybe<T>): Maybe<R> =>
        _.cond([
            [isNothing, createNothing],
            [_.T, _.pipe(_.get('value'), mapper, createJust)]
        ])(maybe);
