import _ from 'lodash/fp';
import { useState } from 'react';
import { Button } from '@elements/fundamentals/Button';
import { useBackgroundActivties } from '@context/BackgroundActivityProvider';
import { FooterButtons } from '@elements/layout/FooterButtons';
import { DynamicForm } from '@elements/forms/DynamicForm';
import { asyncWithFeedback } from '@UIUtils/asyncWithFeedback';
import { Modal } from '../Modal';
import { FormModalProps } from './types';
import { useFormValue } from '@elements/forms/DynamicForm/hooks';
import * as Maybe from '@global/utils/fp/maybe';

export const FormModal = <T extends Record<string, unknown>, O>({
    heading,
    onSubmitSuccess,
    onClose,
    initialContent,
    contentTemplate,
    submitFeedback,
    submitDisplay,
    testID,
    dataSubmitter
}: FormModalProps<T, O>) => {
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { isActivityBlocked } = useBackgroundActivties();

    const [formValueRef, getFormValue] = useFormValue<T>();

    const submitData = dataSubmitter ?? ((x: T) => Promise.resolve(x as unknown as O));

    const handleSubmit = asyncWithFeedback({
        action: _.pipe(getFormValue, Maybe.extract, submitData),
        postAction: _.pipe(Maybe.of, Maybe.map(onSubmitSuccess ?? _.identity), Maybe.map(onClose)),
        message: submitFeedback,
        setIsLoading
    });

    return (
        <Modal
            {...{ heading, onClose, testID }}
            footer={
                <FooterButtons>
                    <Button
                        onPress={handleSubmit}
                        type="primary"
                        disabled={isActivityBlocked('SUBMIT_FORM') || !isValid || isLoading}
                        testID={`${testID}SubmitBtn`}
                    >
                        {submitDisplay}
                    </Button>
                </FooterButtons>
            }
        >
            <DynamicForm
                template={contentTemplate}
                initialValue={initialContent}
                isValidChange={setIsValid}
                getValue={formValueRef}
            />
        </Modal>
    );
};
