import { MaterialCommunityIcons } from '@expo/vector-icons';
import { ScreenProps } from '@global/types';
import { useChannelsContext } from '@global/utils/nativescriptHost/ChannelsProvider';
import { goBack } from '@UIUtils/navigate';
import * as S from '../styles';

export const BackButton = ({ navigation, color }: { navigation: ScreenProps['navigation']; color: string }) => {
    const eventSender = useChannelsContext();

    return (
        <S.Container testID="BackButton" onPress={goBack(navigation, eventSender)}>
            <MaterialCommunityIcons name="arrow-left" size={24} color={color} />
        </S.Container>
    );
};
