import { FeedbackScreen } from '@elements/feedback/FeedbackScreen';
import { useChannelsContext } from '@global/utils/nativescriptHost/ChannelsProvider';
import { handleDone } from './helpers';

export const FtmFormSuccess = () => {
    const eventSender = useChannelsContext();

    return (
        <FeedbackScreen
            type="success"
            primaryButton={{ text: 'Done', handler: handleDone(eventSender), testID: 'DoneBtn' }}
            title="Form saved"
            subtitle="this is a test"
        ></FeedbackScreen>
    );
};
