import { UpdateTaskProps } from './types';
import { TaskFormPage } from '../../components/TaskFormPage';
import { TasksService } from '../../services/tasks';
import { screenName, events } from './metrics';

export const UpdateTask = (props: UpdateTaskProps) => {
    return (
        <TaskFormPage
            heading="Update task"
            subheading="Edit details about the task and what needs to be done."
            submitAction="update"
            navigation={props.navigation}
            initialData={TasksService.mapToFormModel(props.route.params.task)}
            GUID={props.route.params.task.GUID}
            onSubmit={params =>
                props.navigation.navigate('Update task success', {
                    ...params,
                    isTransient: props.route.params.isTransient
                })
            }
            primaryButton={{
                label: 'Save',
                testID: 'SaveBtn'
            }}
            metrics={{ screenName, events }}
        />
    );
};
