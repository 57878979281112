import { Changes, Optional } from '@shared/types/util';
import { metrics as tasksMetrics } from '../screens/Tasks/metrics';
import { metrics as newTaskMetrics } from '../screens/NewTask/metrics';
import { metrics as updateTaskMetrics } from '../screens/UpdateTask/metrics';
import { metrics as taskDetailsMetrics } from '../screens/TaskDetails/metrics';
import { metrics as addNoteMetrics } from '../screens/TaskDetails/modals/AddNote/metrics';
import { metrics as taskSuccessMetric } from '../screens/CreateTaskSuccess/metrics';

export type TaskFormModel = {
    Title: string;
    Status: TaskStatuses;
    'Site location': string;
    'Assigned to': string;
    'Due date'?: number;
    Priority: TaskPriorities;
    Description?: string;
    Photos?: string[];
};

export interface TaskDTO {
    GUID?: string;
    Title: string;
    Assignee: string;
    AssigneeName: string;
    Status: TaskStatuses;
    Priority: TaskPriorities;
    Description?: string;
    Images?: string[];
    CreatedBy?: string;
    ProjectGUID?: string;
    Address?: string;
    DueDate?: number;
    LastModifiedBy?: string;
    CreatedAt?: number;
    LastModifiedAt?: number;
}

export type TaskNoteDTO = Partial<TaskDTO> & { Note: string; Attachments?: string[] };
export type UpdateTaskDTO = Optional<TaskNoteDTO, 'Note'>;

export type TaskStatuses = 'To do' | 'In Progress' | 'Blocked' | 'Done' | "Won't do";

export type TaskPriorities = 'Low' | 'Medium' | 'High' | 'Critical';

export interface NonTeamAssignee {
    Name: string;
    CompanyName: string;
    Phone: string;
    Email: string;
}

export type TaskPriorityIcons =
    | 'alert-outline'
    | 'chevron-up-circle-outline'
    | 'minus-circle-outline'
    | 'chevron-down-circle-outline';

export const TaskPriorityStyles: Record<TaskPriorities, { icon: TaskPriorityIcons; color: string }> = {
    Critical: { icon: 'alert-outline', color: '#E53935' },
    High: { icon: 'chevron-up-circle-outline', color: '#E53935' },
    Medium: { icon: 'minus-circle-outline', color: '#FF6F00' },
    Low: { icon: 'chevron-down-circle-outline', color: '#5C6BC0' }
};

export interface Activity {
    GUID: string;
    TaskGUID: string;
    ModifiedBy: string;
    ModifiedAt: number;
    Changes: Changes<TaskDTO>;
    Note?: string;
    Attachments?: string[];
}

export type FieldChange<T> = Record<'before' | 'after', T>;

// metrics
export const metrics = [
    tasksMetrics,
    newTaskMetrics,
    updateTaskMetrics,
    taskDetailsMetrics,
    addNoteMetrics,
    taskSuccessMetric
];
