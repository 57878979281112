import styled from 'styled-components/native';

export const Wrapper = styled.View`
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
`;
