import { ModuleScreen } from '@global/types';
import { UpdateTask } from './screens/UpdateTask';
import { NewTask } from './screens/NewTask';
import { TaskDetails } from './screens/TaskDetails';
import { Tasks } from './screens/Tasks';
import { CreateTaskSuccess } from './screens/CreateTaskSuccess';
import { UpdateTaskSuccess } from './screens/UpdateTaskSuccess';
import { TaskDTO } from './shared/types';
import { Option } from '@elements/forms/ActionItem';
import _ from 'lodash';

export type TasksModuleParams = {
    'New task': undefined;
    'Update task': UpdateTaskParams;
    'Create task success': TaskSuccessParams;
    'Update task success': TaskSuccessParams;
    Tasks: undefined;
    'Task details': ShowTaskParams;
};

export interface TaskSuccessParams {
    title: string;
    location?: string;
    assignee: string;
    isTransient?: boolean;
}

export interface ShowTaskParams {
    GUID: string;
    isTransient?: boolean;
}

export interface UpdateTaskParams {
    task: TaskDTO;
    assigneeOptions?: Option[];
    addressOptions?: Option[];
    isTransient?: boolean;
}

export type TasksStates = keyof TasksModuleParams;

function getScreenType(route: string, headerlessList: string[]): 'header' | 'headerless' {
    const fullUrl = window.location.href;
    const urlParts = fullUrl.split('#');
    const queryString = urlParts.length > 1 ? urlParts[1].split('?')[1] : '';
    const urlParams = new URLSearchParams(queryString);
    const mode = urlParams.get('mode');

    if (_.some(headerlessList, headerlessRoute => route.includes(headerlessRoute))) {
        return 'headerless';
    }

    return mode === 'embedded' ? 'headerless' : 'header';
}

const headerlessList = ['tasks/update', 'tasks/new/success'];
const screens = [
    {
        name: 'Tasks',
        component: Tasks,
        route: 'tasks',
        isEntryPoint: true
    },
    {
        name: 'New task',
        component: NewTask,
        route: 'tasks/new'
    },
    { name: 'Update task', component: UpdateTask, route: 'tasks/update' },
    {
        name: 'Create task success',
        component: CreateTaskSuccess,
        route: 'tasks/new/success'
    },
    {
        name: 'Update task success',
        component: UpdateTaskSuccess,
        route: 'tasks/update/success'
    },
    { name: 'Task details', component: TaskDetails, route: 'task' }
];

export const tasksScreens: ModuleScreen = {
    screens: _.map(screens, screen => ({ ...screen, type: getScreenType(screen.route, headerlessList) })),
    moduleName: 'tasksGroup'
};
