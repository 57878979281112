// screen name
export const screenName = 'Task list';

// events
export const events = Object.freeze({
    tabNav: {
        Mine: 'Task List - Mine tab' as const,
        Others: 'Task List - Others tab' as const,
        Done: 'Task List - Done tab' as const
    },
    deleteTask: 'Task List - Delete task'
});

export const metrics = Object.freeze({
    screenName: 'Task list',
    events
});
