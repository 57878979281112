import { TemplateNode } from '@elements/forms/DynamicForm';
import { AssociatedGroupUser, ProjectSite, User } from '@shared/types';
import fp from 'lodash/fp';
import { toSiteOptions, toOtherAssigneeOptions, toTeamAssigneeOptions } from '../../screens/TaskDetails/actionOptions';
import { createTaskFormTemplate } from './form';
import { Option } from '@elements/forms/ActionItem';
import { TaskTemplateConfig } from './types';

export interface State {
    formTemplate: TemplateNode[];
    teamAssigneeOptions: Option[];
    otherAssigneeOptions: Option[];
    siteOptions: Option[];
}

export type Action = {
    type: 'INIT';
    users: User[];
    sites: ProjectSite[];
    associatedGroupUsers: AssociatedGroupUser[];
    submitType: 'create' | 'update';
};

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'INIT': {
            const newState = fp.flow(
                ({ users, sites, submitType, associatedGroupUsers }): TaskTemplateConfig => ({
                    associatedGroupUserOptions: toOtherAssigneeOptions(associatedGroupUsers),
                    teamUserOptions: toTeamAssigneeOptions(users),
                    siteOptions: toSiteOptions(sites),
                    readonlyPhotos: submitType !== 'create'
                }),
                fp.over([createTaskFormTemplate, fp.identity]),
                ([formTemplate, { associatedGroupUserOptions, siteOptions, teamUserOptions }]: [
                    TemplateNode[],
                    TaskTemplateConfig
                ]) => ({
                    ...state,
                    formTemplate,
                    teamAssigneeOptions: teamUserOptions,
                    otherAssigneeOptions: associatedGroupUserOptions,
                    siteOptions
                })
            )(action);
            return newState;
        }
        default:
            return state;
    }
};
