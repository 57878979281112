import _ from 'lodash';
import { ListItemConfig } from '@elements/collections/ListItem';
import TasksIcon from '@assets/icons/features/feature-tasks.png';
import { TasksService } from '../../services/tasks';
import { TaskTabLabels } from '../../services/tasks/types';
import { TaskDTO } from '../../shared/types';
import { SingleTabState, TabState } from '@elements/layout/TabScreen';

export const EMPTY_STATE_CONFIG = {
    title: 'No Tasks',
    body: 'Tasks will appear in this list when they are created.',
    icon: TasksIcon
};

export const TASK_TYPES: TaskTabLabels[] = ['Mine', 'Others', 'Done'];

export const INITIAL_TAB_STATE = _.zipObject<SingleTabState<ListItemConfig, TaskDTO>>(
    TASK_TYPES,
    _.times(TASK_TYPES.length, _.constant({ items: [], filteredItems: [], searchText: '' }))
) as TabState<TaskTabLabels, ListItemConfig, TaskDTO>;

export const mapToListItemWithDeleteAction = (
    tasks: TaskDTO[],
    deleteAction: (task: TaskDTO) => Promise<void>
): ListItemConfig[] =>
    TasksService.mapToListItem(tasks).map(task => ({
        ...task,
        actions: [
            {
                label: 'Delete',
                handler: () => deleteAction(_.find(tasks, { GUID: task.GUID })!)
            }
        ]
    }));
export const getTabNameForTask = (task: TaskDTO, user: string): TaskTabLabels =>
    _.cond<TaskDTO, TaskTabLabels>([
        [_.matchesProperty('Status', 'Done'), _.constant('Done')],
        [_.matchesProperty('Assignee', user), _.constant('Mine')],
        [_.stubTrue, _.constant('Others')]
    ])(task);
