import { DropdownOption } from '@elements/forms/Dropdown';
import { TaskDTO } from '../../shared/types';
import _ from 'lodash';

export const patchWithDropdownOption = (
    dropdownValue: string | undefined,
    options: DropdownOption[],
    mapTo: Record<'label' | 'value', string>
): Partial<TaskDTO> => {
    const { label, value } = _.find(options, { value: dropdownValue }) ?? {};
    return {
        [mapTo.label]: label,
        [mapTo.value]: value
    };
};
