import _ from 'lodash';
import Carousel from 'react-native-reanimated-carousel';
import { Dimensions } from 'react-native';
import { Modal } from '@elements/layout/Modal';
import { CarouselItem } from './components/CarouselItem';
import { CarouselModalProps } from './types';

export const CarouselModal = (props: CarouselModalProps) => {
    const { height, width } = Dimensions.get('window');

    return (
        <Modal testID="CarouselModal" heading="Photos" onClose={props.onClose}>
            {props.uris.length > 1 ? (
                <Carousel
                    width={width}
                    height={height * 0.8}
                    data={props.uris}
                    defaultIndex={props.startingIndex}
                    scrollAnimationDuration={1000}
                    onSnapToItem={index => console.log('current index:', index)}
                    renderItem={CarouselItem}
                />
            ) : (
                <CarouselItem item={_.first(props.uris)} />
            )}
        </Modal>
    );
};
