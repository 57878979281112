import { AssociatedGroupUser, ProjectSite, User } from '@shared/types';
import { Option } from '@elements/forms/ActionItem';

export const toTeamAssigneeOptions = (users: User[]): Option[] =>
    [{ FirstName: 'Unassigned', LastName: '', GUID: 'Unassigned' }, ...users]
        ?.map(({ FirstName, LastName, GUID }) => {
            const name = `${FirstName} ${LastName}`.trim();
            return {
                label: name,
                value: GUID,
                icon: name,
                color: '#0091EA'
            };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

export const toOtherAssigneeOptions = (users?: AssociatedGroupUser[]): Option[] =>
    (users ?? [])
        .map(({ name, userGUID, company }) => {
            return {
                label: name,
                subtitle: company,
                value: userGUID,
                icon: name,
                color: '#0091EA'
            };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

export const toSiteOptions = (sites: ProjectSite[]): Option[] =>
    [{ Address: 'No site location', GUID: '' }, ...sites].map(({ Address, GUID }) => ({
        label: Address,
        value: GUID,
        icon: 'map-marker',
        color: '#63646a'
    }));
