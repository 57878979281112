import { useUserContext } from '@context/UserProvider';
import { safeFormatDateForDateField } from '@global/utils/helpers/date';
import _ from 'lodash/fp';
import { useState } from 'react';
import * as S from './styles';
import { DatePickerProps } from './types';

export const DatePicker = ({ onChange, value, ...props }: DatePickerProps) => {
    const [isFocused, setIsFocused] = useState<boolean>();

    const { user } = useUserContext();

    return (
        <S.DateInput
            {...props}
            value={safeFormatDateForDateField(user)(value)}
            isFocused={isFocused}
            onChange={_.flow(_.property('target.valueAsDate'), onChange)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            type="date"
            placeholder="Select a date"
        />
    );
};
