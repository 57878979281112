export const isURI = (uri?: string): uri is string => {
    if (!uri) return false;

    try {
        new URL(uri);
        return true;
    } catch {
        return false;
    }
};

export const wait = (time = 2000) => new Promise(resolve => setTimeout(resolve, time));

export const isEmbedded = (): boolean => window.top !== window.self;
