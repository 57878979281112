import { useMemo } from 'react';
import { useUserContext } from '@context/UserProvider';
import { useChannelsContext } from '@global/utils/nativescriptHost/ChannelsProvider';

/**
 * Token Authentication
 * Check for tokens, authenticate and set the User state
 */
export const AuthContainer = ({ children }: { children: JSX.Element }) => {
    const { setAuthenticatedUser, setToken } = useUserContext();
    const eventSender = useChannelsContext();

    useMemo(() => {
        eventSender?.getState({ key: ['user'] }).then(async userResult => {
            if (userResult?.type === 'success' && userResult.result?.user) {
                await eventSender?.log({
                    message: 'authenticating with user',
                    data: userResult.result.user,
                    type: 'Info'
                });
                await setAuthenticatedUser(userResult.result.user);
            }
        });
        // check token for backwards compatibility
        eventSender?.getState({ key: ['userToken'] }).then(async tokenResult => {
            if (tokenResult?.type === 'success' && tokenResult.result?.userToken) {
                await eventSender?.log({ message: 'authenticating with token', data: {}, type: 'Info' });
                await setToken(tokenResult.result.userToken);
            }
        });
    }, []);

    return children;
};
