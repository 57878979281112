import { UploadableFeatures } from '@global/services/files';
import { FieldProps } from '../Field.type';
import { FileUpload } from '../../../FileUpload';

type Props = {
    readonly: boolean;
    feature: UploadableFeatures; // todo: use build env var to decide which upload method
};

type Validators = 'Required';

export const FileUploadField = (props: FieldProps<string[], Props, Validators>) => {
    const mappedProps = {
        ...props,
        label: props.title,
        name: props.title,
        onChange: props.onModalValueChanged
    } as const;

    return <FileUpload {...mappedProps} />;
};
