import { AusState, Locale } from '@shared/enums';
import { DateTime } from 'luxon';
import _ from 'lodash';

export interface UserLocale {
    Locale?: Locale;
    State?: AusState;
}

const toLocalisedDate = _.curry((user: UserLocale, date: Date | number | string) =>
    DateTime.fromJSDate(new Date(date))
        .setLocale(localeMap[user.Locale ?? Locale.NZ])
        .setZone(timezoneMap[user.Locale ?? Locale.NZ][user.State ?? 'UNKNOWN'])
);

export const formatDateTime = _.flow(toLocalisedDate, x =>
    x.toLocaleString({
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    })
);

const timezoneMap: Record<Locale, Partial<Record<AusState | 'UNKNOWN', string>>> = {
    [Locale.NZ]: {
        UNKNOWN: 'Pacific/Auckland'
    },
    [Locale.AU]: {
        [AusState.VIC]: 'Australia/Melbourne',
        [AusState.NSW]: 'Australia/Sydney',
        [AusState.QLD]: 'Australia/Brisbane',
        [AusState.WA]: 'Australia/Perth',
        [AusState.TAS]: 'Australia/Hobart',
        [AusState.SA]: 'Australia/Adelaide',
        [AusState.NT]: 'Australia/Darwin',
        [AusState.ACT]: 'Australia/Sydney',
        UNKNOWN: 'Australia/Melbourne'
    },
    [Locale.UK]: {
        UNKNOWN: 'Europe/London'
    }
};

const localeMap: Record<Locale, string> = {
    [Locale.NZ]: 'en-GB',
    [Locale.AU]: 'en-GB',
    [Locale.UK]: 'en-GB'
};

const formatAsMediumWithWeekday = (x: DateTime) => x.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);

const formatAsYYYYmmdd = (x: DateTime) => x.toISODate();

const formatAsDDMMYYYY = (x: DateTime) => x.toLocaleString(DateTime.DATE_SHORT);

const dateFormatterGuard = (item?: unknown): item is Date | number | string =>
    (typeof item === 'number' || typeof item === 'string' || item instanceof Date) && !isNaN(new Date(item).valueOf());

const safeFormat = <T>(
    formatter: (item: T) => string,
    guard: (item: unknown) => item is T,
    item?: unknown,
    defaultString = ''
) => (guard(item) ? formatter(item) : defaultString);

export const safeFormatDateForDateField = (user: UserLocale) =>
    _.partial(safeFormat, _.flow(toLocalisedDate(user), formatAsYYYYmmdd), dateFormatterGuard);

export const safeFormatShortDate = (user: UserLocale) =>
    _.partial(safeFormat, _.flow(toLocalisedDate(user), formatAsDDMMYYYY), dateFormatterGuard);

export const safeFormatMediumDate = (user: UserLocale) =>
    _.partial(safeFormat, _.flow(toLocalisedDate(user), formatAsMediumWithWeekday), dateFormatterGuard);
