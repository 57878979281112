import { ProjectSite } from '@shared/types';
import { User } from '@shared/types/user';
import { ServerlessAPI } from '../api.config';

const forgotPassword = async (email: string) => {
    return ServerlessAPI.post<{ Message: string }>('/user/forgot-password', { email }).then(x => x.data);
};

const getSiteList = () => ServerlessAPI.get<ProjectSite[]>('/user/site-list').then(({ data }) => data);

/**
 * Token Validation
 */
const auth = async (token: string): Promise<User> => {
    const config = {
        headers: {
            Authorization: 'Bearer ' + token
        }
    };
    return ServerlessAPI.get<User>('/user/session', config).then(x => x.data);
};

const login = async (email: string, password: string): Promise<User> => {
    return ServerlessAPI.post<User>('/user/login', { Email: email, Password: password }).then(({ data }) => data);
};

export const UserService = {
    forgotPassword,
    auth,
    getSiteList,
    login
};
