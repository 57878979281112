import _ from 'lodash';
import fp from 'lodash/fp';
import { FieldChange } from '@shared/types';

export const getChangeTextContent = (key: string, changes: { before?: unknown; after?: unknown }) =>
    _.flow(
        fp.mapValues(x => x ?? undefined),
        fp.defaults({ before: undefined, after: undefined }),
        _.cond<FieldChange<string>, string>([
            [_.matches({ before: undefined }), ({ after }) => `${key} was added: '${after}'`],
            [_.matches({ after: undefined }), _.constant(`${key} was removed`)],
            [_.stubTrue, ({ before, after }) => `'${before}' → '${after}'`]
        ])
    )(changes);
