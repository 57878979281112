import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { ActionItemProps, Option } from './types';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { FormModal } from '@elements/layout/FormModal/FormModal';
import {
    mapLabelValuePairs,
    mapCoreOption,
    mapSupportingDto as mapSupportingDto,
    getDefaultOption,
    liftChildrenContent,
    mapSubmissionItem
} from './helpers';
import * as S from './styles';
import _ from 'lodash';
import fp from 'lodash/fp';
import { Icon } from '@elements/fundamentals/Icon/Icon';
import { useUserContext } from '@context/UserProvider';
import { useChannelsContext } from '@global/utils/nativescriptHost/ChannelsProvider';

export const ActionItem = ({ event, ...props }: ActionItemProps) => {
    const {
        label,
        iconType,
        required,
        entity: { Type, Icon: icon },
        options
    } = props;

    const { user } = useUserContext();
    const eventSender = useChannelsContext();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<Option | undefined>();
    const modalTemplate = useMemo(() => liftChildrenContent(props.entity), [props.entity]);

    useEffect(() => {
        const defaultOption = getDefaultOption(props, user);
        if (!_.isEqual(defaultOption, selectedOption)) {
            setSelectedOption(defaultOption);
        }
    }, [props, selectedOption, user]);

    const handleTap = () => {
        event && eventSender?.logEvent({ event });
        setIsModalOpen(true);
    };

    const mapCoreOptionPartial = _.partial(mapCoreOption, Type, { options, icon }, user);
    const handleSubmit = fp.flow(
        mapLabelValuePairs,
        ([head, ...tails]) => [mapCoreOptionPartial(head?.[label]), mapSupportingDto(tails)] as const,
        fp.tap(([coreOption]) => setSelectedOption(coreOption)),
        ([coreOption, supporting]) => [mapSubmissionItem(label, coreOption), supporting],
        fp.spread(props.onSubmit)
    );

    const testID = `${label}Action`;
    const iconProps = selectedOption
        ? _(selectedOption).pick(['icon', 'color']).merge({ iconType }).value()
        : {
              // default icon
              icon,
              iconType: 'glyphicon' as const,
              color: '#e3e3e3'
          };

    return (
        <Fragment>
            <S.Item onPress={handleTap} {...{ testID }}>
                <Icon {...iconProps} style="big" />
                <S.Content>
                    <S.Title>
                        {label} {required ? '' : '(optional)'}
                    </S.Title>
                    <S.Value>{selectedOption?.label ?? props.primaryButtonText}</S.Value>
                </S.Content>
                <S.Indicator>
                    <MaterialCommunityIcons name="chevron-right" size={24} color="#bcbcbc" />
                </S.Indicator>
            </S.Item>
            {isModalOpen && (
                <FormModal
                    heading={props.modalHeading}
                    initialContent={{ [label]: selectedOption?.value ?? '' }}
                    onClose={() => setIsModalOpen(false)}
                    submitDisplay={props.primaryButtonText}
                    onSubmitSuccess={handleSubmit}
                    contentTemplate={modalTemplate}
                    submitFeedback={{ error: 'There was an error' }}
                    testID={`${testID}Modal`}
                />
            )}
        </Fragment>
    );
};
