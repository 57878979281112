import { useState } from 'react';
import { Screen } from '@elements/layout/Screen';
import { Button } from '@elements/fundamentals/Button';
import { Heading } from '@elements/typography/Heading';
import { Paragraph } from '@elements/typography/Paragraph';
import { DynamicForm } from '@elements/forms/DynamicForm';
import { FooterButtons } from '@elements/layout/FooterButtons';
import { ForgotPasswordForm } from './form';
import { ForgotPasswordProps } from './types';
import { handleSubmit } from './helpers';
import { useChannelsContext } from '@global/utils/nativescriptHost/ChannelsProvider';
import { screenName, events } from './metrics';
import { useFormValue } from '@elements/forms/DynamicForm/hooks';
import _ from 'lodash/fp';
import * as Maybe from '@global/utils/fp/maybe';

export const ForgotPassword = ({ navigation }: ForgotPasswordProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const eventSender = useChannelsContext();
    const [formValueRef, getFormValue] = useFormValue<{ email: string }>();

    return (
        <Screen
            screenName={screenName}
            isLoading={isLoading}
            footer={
                <FooterButtons>
                    <Button
                        type="primary"
                        onPress={_.pipe(
                            getFormValue,
                            Maybe.map(v => {
                                eventSender?.logEvent({ event: events.sendNewPassword });
                                handleSubmit(navigation, v, setIsLoading);
                            })
                        )}
                        disabled={!isValid}
                        testID="SendNewPasswordBtn"
                    >
                        Send new password
                    </Button>
                </FooterButtons>
            }
        >
            <Heading type="page">Reset password</Heading>
            <Paragraph>
                Enter the email address you registered with HazardCo and we&#39;ll send you instructions to update your
                password.
            </Paragraph>
            <DynamicForm template={ForgotPasswordForm} isValidChange={setIsValid} getValue={formValueRef} />
        </Screen>
    );
};
