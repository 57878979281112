import _ from 'lodash';
import fp from 'lodash/fp';
import Toast from 'react-native-root-toast';
import { ChangeEvent, useCallback, useRef, useState, ElementType, useMemo, useEffect } from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { FilesService } from '@global/services/files';
import { useBackgroundActivties } from '@context/BackgroundActivityProvider';
import { Gallery } from '@elements/collections/Gallery';
import { InputContainer, Label } from '../shared/styles';
import { File, FileUploadProps } from './types';
import { openFilePicker } from './helpers';
import * as S from './styles';
import { asyncWithFeedback } from '@UIUtils/asyncWithFeedback';

// TODO: When reimplementing this component for native iOS and Android,
// remove the HTML input component and leverage Expo Imagepicker (https://docs.expo.dev/versions/latest/sdk/imagepicker/)
// instead as this has cross-platform support. A web element is used in the origina
// use case as the Expo version for web will always generate a base64 image
// which is expensive to compute on the client-side.
export const FileUpload = ({ onChange, limit = 5, ...props }: FileUploadProps) => {
    // todo: use useReducer to centralise managing of image transition
    const [images, setImages] = useState<File[]>([]);

    const parseValue = (value: string[] | undefined) =>
        (value ?? []).map((uri: string) => ({
            uri,
            isLoading: false,
            local: _.find(images, { uri })?.local
        }));

    useEffect(() => {
        setImages(parseValue(props.value));
    }, [props.value]);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const { startBlockingActivity, stopBlockingActivity } = useBackgroundActivties();

    const selectImagesToUpload = useCallback(
        (selected: File[], currentCount: number) => {
            if (selected.length + currentCount > limit) {
                Toast.show("You can only upload limit images at a time. We've selected the first limit you picked.", {
                    duration: Toast.durations.LONG
                });
                return _.take(selected, limit - currentCount);
            }
            return selected;
        },
        [limit]
    );

    const handleChange = useCallback(
        (uris: string[]) => {
            const { label } = props;
            onChange({ label, field: label, value: uris });
        },
        [onChange, props]
    );

    const extractURI = (files: File[]) => files.map(({ uri }) => uri);

    const removeImage = (index: number) => {
        const afterRemoval = images.filter((_, idx) => idx !== index);
        setImages(afterRemoval);
        handleChange(extractURI(afterRemoval));
    };

    const handleFileInputChange = async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
        if (!files) return;

        startBlockingActivity('SUBMIT_FORM');

        const placeholderImages = _.flow(
            fp.times(index => files.item(index)),
            fp.compact,
            fp.map(file => ({ uri: file.name, isLoading: true, local: URL.createObjectURL(file) }))
        )(files.length);
        startBlockingActivity('SUBMIT_FORM');
        setImages(existing => [...existing, ...selectImagesToUpload(placeholderImages, images.length)]);
        asyncWithFeedback({
            action: async () => {
                const uploaded = await FilesService.uploadFiles(files, props.feature);
                setImages(existing => {
                    const updated = existing.map(({ uri, local }) => ({
                        uri: _.find(uploaded, { originalFilename: uri })?.filename ?? uri,
                        isLoading: false,
                        local
                    }));
                    handleChange(updated.map(({ uri }) => uri));
                    return updated;
                });
            },
            onError: () => setImages(images),
            postAction: () => stopBlockingActivity('SUBMIT_FORM')
        })();
    };

    const Container = useMemo(
        () => (props.disabled ? S.DisabledEmptyContainer : S.EmptyContainer),
        [props.disabled]
    ) as ElementType;

    const placeholderText = useMemo(() => {
        if (props.disabled) {
            return `Adding ${props.label.toLowerCase()} is disabled`;
        }
        return `Add photos${props.required ? '' : ' (optional)'}`;
    }, [props.disabled, props.required, props.label]);

    return (
        <InputContainer>
            <Label>{props.label}</Label>

            {images.length ? (
                <Gallery
                    readonly={props.readonly}
                    uris={images}
                    onRemove={removeImage}
                    showAddButton={images.length < limit && !props.readonly}
                    testID={props.testID}
                    onAdd={() => openFilePicker(fileInputRef.current)}
                />
            ) : (
                <Container
                    onPress={() => !props.readonly && !props.disabled && openFilePicker(fileInputRef.current)}
                    testID={`${props.testID}_addFileBtn`}
                >
                    <MaterialCommunityIcons
                        name={props.disabled ? 'camera-off' : 'camera'}
                        color={props.disabled ? '#bcbcbc' : '#0091ea'}
                        size={32}
                    />
                    {props.readonly ? (
                        <S.Placeholder>No images are available</S.Placeholder>
                    ) : (
                        <>
                            <S.Placeholder>{placeholderText}</S.Placeholder>
                            {!props.disabled && <S.Limit>Maximum {limit} images</S.Limit>}
                        </>
                    )}
                </Container>
            )}
            <S.InvisibleInput
                type="file"
                multiple
                accept=".png,.jpg"
                ref={fileInputRef}
                onChange={handleFileInputChange}
            />
        </InputContainer>
    );
};
