import { Icon } from '@elements/fundamentals/Icon';
import { RadioProps } from './types';
import * as S from './styles';

export const Radio = ({ disabled, ...props }: RadioProps) => {
    const handlePress = () => (disabled ? null : props.onPress?.(props.id));

    return (
        <S.Pressable
            as={disabled ? S.NotPressable : undefined}
            onPress={handlePress}
            testID={`${props.testID}${props.title}_RadioBtn`}
        >
            <S.Content>
                {props.icon && (
                    <S.Icon>
                        <Icon {...props.icon} iconSize={24} style="small" />
                    </S.Icon>
                )}
                <S.Title disabled={disabled} isHeading={!!props.subtitle}>
                    {props.title}
                </S.Title>
                <S.Marker testID={`${props.testID}_RadioMarker`} selected={props.selected} disabled={disabled}>
                    {props.selected && <S.Inner testID={`${props.testID}_ActiveRadioMarker`} disabled={disabled} />}
                </S.Marker>
            </S.Content>
            {props.subtitle && <S.Subtitle disabled={disabled}>{props.subtitle}</S.Subtitle>}
        </S.Pressable>
    );
};
