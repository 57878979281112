import styled from 'styled-components/native';

export const Icon = styled.View<{ color?: string }>`
    height: 48px;
    width: 48px;
    background-color: ${({ color }) => color ?? '#1F2129'};
    align-items: center;
    justify-content: center;
    border-radius: 48px;
`;

export const SmallIcon = styled.View``;

export const HazardCoIcon = styled.Image`
    height: 20px;
    width: 20px;
`;
