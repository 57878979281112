import styled from 'styled-components/native';

export const Container = styled.View`
    padding: 24px 8px 32px 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ffffff;
    border: solid transparent 0.5px;
    border-top-color: #bcbcbc;
    width: 100%;
`;
