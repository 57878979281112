import styled from 'styled-components/native';
import { Dimensions } from 'react-native';
import { ScreenProps } from './types';

export const Screen = styled.SafeAreaView<ScreenProps>`
    height: 100%;
    width: ${Dimensions.get('window').width}px;
    background-color: ${({ background }) => (background ? 'transparent' : '#ffffff')};
    flex: 1;
`;

export const StaticView = styled.View`
    width: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
`;

export const Background = styled.ImageBackground`
    width: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
`;

export const BottomSafeArea = styled.SafeAreaView`
    flex: 0;
    background-color: #ffffff;
`;
