export const screenName = 'Task Creation';
export const events = Object.freeze({
    cancel: 'Task Creation - Cancel',
    mainAction: 'Task Creation - Add'
});

export const metrics = Object.freeze({
    screenName,
    events
});
