import React from 'react';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { SearchBarProps } from './types';
import * as S from './styles';

export const SearchBar = (props: SearchBarProps) => {
    return (
        <S.Container isHeader={props.isHeader}>
            <S.Icon>
                <Icon name="magnify" color="#8E8E93" size={24} />
            </S.Icon>
            <S.Input {...props} testID="searchBarField" />
        </S.Container>
    );
};
