import { useRef } from 'react';
import _ from 'lodash/fp';
import { of, map } from '@global/utils/fp/maybe';

export const useFormValue = <T = any>() => {
    const formValueRef = useRef<() => T>(null);
    const getFormValue = _.pipe(
        _.constant(of(formValueRef.current)),
        map(x => x())
    );

    return [formValueRef, getFormValue] as const;
};
