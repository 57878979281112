import React from 'react';
import { Avatar } from '@elements/fundamentals/Avatar';
import { Gallery } from '../Gallery';
import { formatDateTime } from '@global/utils/helpers/date';
import { useUserContext } from '@context/UserProvider';
import { ActivityFeedItemProps, FeedItemContent } from './types';
import { getChangeTextContent } from './helpers';
import * as S from './styles';

export const ActivityFeedItem = (props: ActivityFeedItemProps) => {
    const { user } = useUserContext();

    const formatChangeContent = (changes: FeedItemContent, showExtras: boolean) => {
        const { previous, property, updated } = changes;

        return [
            {
                shouldRender: !!property,
                title: property,
                content: property && getChangeTextContent(property, { before: previous, after: updated })
            },
            {
                shouldRender: props.note && showExtras,
                title: 'Notes',
                content: props.note
            },
            {
                shouldRender: !!props.images?.length && showExtras,
                title: 'Attachments',
                content: props.images && (
                    <Gallery
                        testID={`Gallery_${props.timestamp}`}
                        uris={props.images.map(uri => ({ uri, isLoading: false }))}
                        readonly
                    />
                ),
                displayAsColumn: true
            }
        ];
    };

    return (
        <S.Item>
            <S.Header>
                <Avatar size={48} name={props.creator} />
                <S.HeaderContent>
                    <S.Title>{props.creator}</S.Title>
                    <S.Subtitle>{formatDateTime(user, props.timestamp)}</S.Subtitle>
                </S.HeaderContent>
            </S.Header>
            <S.Changes>
                {[...props.content, {}] // An empty object will force the component to render a Note if it exists
                    .flatMap((changes, index, list) => formatChangeContent(changes, index === list.length - 1))
                    .filter(({ shouldRender }) => shouldRender)
                    .map(({ content, title, displayAsColumn }, index) => (
                        <S.Content key={`ActivityFeedItem__${index}_${title}`}>
                            <S.Text column={!!displayAsColumn}>
                                <S.Property>{title}: </S.Property>
                                {content}
                            </S.Text>
                        </S.Content>
                    ))}
            </S.Changes>
        </S.Item>
    );
};
