import { ActionItem, Option } from '@elements/forms/ActionItem';
import { IconProps } from '@elements/fundamentals/Icon';
import { LogEvent } from '@global/metrics/types';
import { capitaliseKeys } from '@global/utils/helpers/object';
import { TemplateTabConfig } from '../../types';
import { FieldProps } from '../Field.type';

type Props = {
    submitText: string;
    icon: string;
    iconType: IconProps['iconType'];
    eventName: LogEvent;
    options: Option[] | TemplateTabConfig[];
    type: keyof typeof typeMap;
};

const typeMap = {
    DatePicker: 'DateField',
    ListPicker: 'RadioGroup'
} as const;

export const ActionItemField = ({ id, ...props }: FieldProps<string, Props, 'Required'>) => {
    // const options = hasTabbedOptions(props.options) ? (_.flatMap(props.options, 'Options') as Option[]) : props.options;
    const { type } = props;

    const mappedProps = {
        ...props,
        entity: {
            ...capitaliseKeys(props),
            ID: id,
            Type: typeMap[type],
            label: props.title,
            selected: props.value,
            Options: props.options
        },
        primaryButtonText: props.submitText,
        label: props.title,
        modalHeading: props.submitText,
        required: props.validators?.Required,
        onSubmit: props.onModalValueChanged,
        event: props.eventName,
        options: props.options
    } as const;

    return <ActionItem {...mappedProps} />;
};
