import { TemplateNode } from '@elements/forms/DynamicForm';
import { supportedFields } from '@elements/forms/DynamicForm/fields/supportedFields';
import { ServerlessAPI } from '@global/services/api.config';
import _ from 'lodash/fp';
import { FormPayload, FTMField, FTMForm, FTMSubmitDto } from '../../shared/types';

const get = (templateId: string, formId: string | undefined): Promise<FTMForm<TemplateNode>> =>
    formId ? getWithValues(templateId, formId) : getTemplate(templateId);

/**
 * Get template
 */
const getTemplate = (GUID: string): Promise<FTMForm<TemplateNode>> =>
    ServerlessAPI.get<FTMForm<FTMField>>(`/form-template/${GUID}/json`).then(({ data }) => mapToDFForm(data));

/**
 * Get template populated with a submitted form
 */
const getWithValues = (templateId: string, formId: string): Promise<any> =>
    ServerlessAPI.get<FTMForm<FTMField>>(`/form-template/form/${formId}`).then(({ data }) => mapToDFForm(data));

const mapToDFForm = ({ Sections, ...form }: FTMForm<FTMField>) => ({
    ...form,
    Sections: Sections.map(s => ({
        ...s,
        Fields: mapToTemplateNode(s.Fields)
    }))
});

const mapToTemplateNode = (fields: Readonly<FTMField[]>): TemplateNode[] =>
    fields.filter(isSupported).map(f => ({
        ...f,
        Options:
            f.Options?.map(({ Title: label, ID: value, Icon: icon, Color: color, Subtitle: subtitle }) => ({
                label,
                value,
                icon,
                color,
                subtitle
            })) || []
    }));

const save = (templateID: string, payload: FormPayload): Promise<any> =>
    ServerlessAPI.post<FTMSubmitDto>(`/form-template/${templateID}/form`, {
        TemplateID: templateID,
        Fields: payload
    }).then(({ data }) => data);

const isSupported = (field: FTMField): boolean => _.includes(field.Type, supportedFields);

export const TemplatesService = {
    get,
    save
};
