import { IconProps } from '@elements/fundamentals/Icon';
import { RadioGroup } from '../../../RadioGroup';
import { FieldProps } from '../Field.type';

type props = {
    options: {
        id: string;
        title: string;
        subtitle?: string;
        icon?: string;
        color?: string;
    }[];
    iconType: IconProps['iconType'];
};

type Validators = 'Required' | 'OneOf';

export const RadioGroupField = (props: FieldProps<string, props, Validators>) => {
    const options = props.options?.map(({ id, title, ...fs }) => ({ label: title, value: id, ...fs })) ?? [];
    const others = {
        label: props.title,
        options,
        selected: props.value,
        iconType: props.iconType,
        onChange: props.onValueChange
    };
    return <RadioGroup {...{ ...props, ...others }} />;
};
