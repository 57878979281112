import styled from 'styled-components/native';

export const Main = styled.View`
    margin-top: auto;
    align-items: center;
`;

export const Icon = styled.View`
    align-self: center;
`;

export const Container = styled.View`
    padding: 32px 24px;
    margin-top: auto;
    background-color: #ffffff;
    min-width: 100%;
    max-width: 100%;
`;
