import { UserService } from '@global/services/user/user.service';
import { CredentialModuleParams } from '../../routing';
import { ForgotPasswordProps } from './types';

export const handleSubmit = async (
    navigator: ForgotPasswordProps['navigation'],
    params: CredentialModuleParams['Forgot password success'],
    setIsLoading: (isLoading: boolean) => void
) => {
    setIsLoading(true);
    UserService.forgotPassword(params.email)
        .then(() => navigator.navigate('Forgot password success', params))
        .catch(() => navigator.navigate('Server error'))
        .finally(() => setIsLoading(false));
};
