import { FormModal } from '@elements/layout/FormModal';
import { useLogScreenName } from '@global/metrics/hooks';
import { useChannelsContext } from '@global/utils/nativescriptHost/ChannelsProvider';
import { fileUploadFormEntity, notesFormEntity } from '../../../../components/TaskFormPage';
import { TasksService } from '../../../../services/tasks';
import { screenName, events } from './metrics';
import { AddNoteModalProps, ContentType } from './types';

export const AddNoteModal = ({ taskGUID, onClose }: AddNoteModalProps) => {
    const eventSender = useChannelsContext();
    useLogScreenName(screenName);

    const saveNote = ({ Note, Photos }: ContentType) => {
        eventSender?.logEvent({ event: events.saveNote });
        return TasksService.addNote({
            Attachments: Photos,
            Note,
            GUID: taskGUID
        });
    };

    return (
        <FormModal
            heading="Add note"
            initialContent={{ Note: '' }}
            submitDisplay="Save Note"
            contentTemplate={[notesFormEntity, fileUploadFormEntity]}
            dataSubmitter={saveNote}
            onClose={onClose}
            submitFeedback={{
                success: 'Note saved',
                error: 'Error in saving note. Please try again later.'
            }}
            testID="AddNoteModal"
        />
    );
};
