import { useCallback, useEffect, useState } from 'react';
import { FocusEvent, NativeFocusEvent, TextInputProps } from './types';
import { InputContainer, Label, Buffer, Error } from '../shared/styles';
import { scrollIntoView } from './helpers';
import * as S from './styles';

export const TextInput = ({ onChangeText, onBlur, value, ...props }: TextInputProps) => {
    const [text, setText] = useState('');
    const [hasErrors, setHasErrors] = useState<boolean>();
    const [isFocused, setIsFocused] = useState<boolean>();

    useEffect(() => {
        value && setText(value);
    }, [value]);

    useEffect(() => {
        setHasErrors(!!(props.touched && props.errors));
    }, [props.errors, props.touched]);

    const handleFocus = (event: FocusEvent) => {
        if (props.disabled) return;

        setIsFocused(true);
        scrollIntoView(event);
    };

    const handleBlur = useCallback(
        (event: NativeFocusEvent) => {
            // These conditions will always be true in reality,
            // but the `event` will not be during unit tests.
            // This maintains the core behaviour and prevents testing errors.
            if (onBlur && event) onBlur(event);
            if (onChangeText) onChangeText(text);
            setIsFocused(false);
        },
        [onBlur, onChangeText, text]
    );

    return (
        <InputContainer isHidden={props.type === 'hidden'} key={props.key}>
            <Label>{`${props.label} ${props.required ? '' : '(optional)'}`}</Label>
            <S.Input
                textContentType={props.type === 'emailAddress' ? 'emailAddress' : undefined}
                keyboardType={props.type === 'emailAddress' ? 'email-address' : 'default'}
                autoCapitalize={props.type === 'emailAddress' ? 'none' : 'sentences'}
                secureTextEntry={props.type === 'password'}
                {...{ ...props, hasErrors, isFocused }}
                value={text}
                onChangeText={setText}
                onFocus={handleFocus}
                onBlur={handleBlur}
                clearButtonMode="while-editing"
                accessibilityState={{ disabled: props.disabled }}
            />
            {hasErrors ? <Error>{props.errors}</Error> : <Buffer />}
        </InputContainer>
    );
};
