import _ from 'lodash';
import { Dropdown } from '../../../Dropdown';
import { FieldProps } from '../Field.type';

type DropdownProps = {
    options: {
        id: string;
        title: string;
    }[];
};

type Validators = 'Required' | 'OneOf';

export const DropdownField = (props: FieldProps<string, DropdownProps, Validators>) => {
    const options = props.options?.map(({ id, title }) => ({ label: title, value: id })) ?? [];
    const others = {
        label: props.title,
        name: props.title,
        options,
        value: _.find(options, { value: props.value ?? '' })
    };

    return <Dropdown {...{ ...props, ...others }} />;
};
