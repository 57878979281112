import React, { useCallback, useEffect, useReducer } from 'react';
import { ActivityIndicator } from 'react-native';
import { Heading } from '@elements/typography/Heading';
import { Button } from '@elements/fundamentals/Button';
import { ActivityFeedItem } from '../ActivityFeedItem';
import { FeedItem } from '../ActivityFeedItem';
import { ActivityFeedProps } from './types';
import * as S from './styles';
import { asyncWithFeedback } from '@UIUtils/asyncWithFeedback';
import _ from 'lodash';
import * as Maybe from '@global/utils/fp/maybe';

export interface State {
    feeds: FeedItem[];
    lastEvaluatedKey?: any;
    isLoading: boolean;
}

export type Action =
    | {
          type: 'LOADING';
      }
    | {
          type: 'REFRESH';
          feeds: FeedItem[];
          lastEvaluatedKey: any;
      }
    | {
          type: 'ADD';
          feeds: FeedItem[];
          lastEvaluatedKey: any;
      };

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'LOADING':
            return { ...state, isLoading: true };
        case 'REFRESH':
            return { feeds: action.feeds, lastEvaluatedKey: action.lastEvaluatedKey, isLoading: false };
        case 'ADD':
            return {
                feeds: state.feeds.concat(action.feeds),
                lastEvaluatedKey: action.lastEvaluatedKey,
                isLoading: false
            };
    }
};

export const ActivityFeed = <T extends object>({
    dataGetter,
    dataMapper,
    registerRefresh,
    ...props
}: ActivityFeedProps<T>) => {
    const [{ feeds, lastEvaluatedKey, isLoading }, dispatch] = useReducer(reducer, {
        feeds: [],
        isLoading: false
    });

    const loadMore = useCallback(
        (type: 'REFRESH' | 'ADD') =>
            asyncWithFeedback({
                action: () => {
                    dispatch({ type: 'LOADING' });
                    return dataGetter(type === 'ADD' ? lastEvaluatedKey : undefined);
                },
                postAction: _.flow(
                    Maybe.of,
                    Maybe.map(({ data, lastEvaluatedKey }) => [dataMapper(data), lastEvaluatedKey] as const),
                    Maybe.map(([feeds, lastEvaluatedKey]) => dispatch({ type, feeds, lastEvaluatedKey }))
                ),
                message: {
                    error: "we couldn't load the the activities. Try again later"
                }
            }),
        [dataGetter, dataMapper, lastEvaluatedKey]
    );

    useEffect(() => registerRefresh(loadMore('REFRESH')), []);

    return feeds.length ? (
        <S.Wrapper>
            <Heading type="section">{props.heading}</Heading>
            <S.Container>
                {feeds.map((item, index) => (
                    <ActivityFeedItem {...item} key={`activity-feed-item-${index}`} />
                ))}
                {isLoading ? (
                    <ActivityIndicator size="large" color="#8a8a8e" />
                ) : (
                    lastEvaluatedKey && (
                        <Button testID="ActivityFeedLoadMoreBtn" onPress={loadMore('ADD')} type="secondary">
                            {props.loadMoreTitle ?? 'Load more'}
                        </Button>
                    )
                )}
            </S.Container>
        </S.Wrapper>
    ) : null;
};
