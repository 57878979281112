import { Text } from '../../components/Text';
import { FieldProps } from '../Field.type';

type TextFieldProps = {
    placeholder?: string;
    isPassword?: boolean;
    autoCorrect?: boolean;
};

type Validators = 'Required' | 'Email' | 'MinLength' | 'MaxLength' | 'Pattern';

export const TextField = (props: FieldProps<string, TextFieldProps, Validators>) => {
    const textProps = {
        ...props,
        placeholder: props.placeholder,
        label: props.title,
        name: props.title,
        type: props.isPassword ? 'password' : 'text',
        onChangeText: props.onValueChange
    } as const;
    return <Text {...textProps} />;
};
