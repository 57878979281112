import React from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import * as S from '../styles';

export const MoreButton = ({ color, onPress }: { color: string; onPress: () => void }) => {
    return (
        <S.Container testID="MoreButton" onPress={onPress}>
            <MaterialCommunityIcons name="dots-horizontal" size={24} color={color} />
        </S.Container>
    );
};
