import Picker from 'react-native-picker-select';
import { useEffect, useMemo, useState } from 'react';
import { InputContainer, Buffer, Error, Label } from '../shared/styles';
import { getInputBackgroundColor, getInputBorderColor, getInputTextColor } from '../util';
import { DropdownProps } from './types';
import * as S from './styles';

const BLANK_OPTION = { label: 'Select an option', value: '' };

export const Dropdown = ({ options, onValueChange, disabled, ...props }: DropdownProps) => {
    const [hasErrors, setHasErrors] = useState<boolean>();
    const [isFocused, setIsFocused] = useState<boolean>();

    const dropdownStyles = useMemo(
        () => ({
            borderColor: getInputBorderColor(hasErrors, isFocused, disabled),
            backgroundColor: getInputBackgroundColor(disabled),
            color: getInputTextColor(disabled),
            ...S.Dropdown
        }),
        [hasErrors, isFocused, disabled]
    );

    const handleBlur = () => {
        setIsFocused(false);
        props.onBlur();
    };

    useEffect(() => {
        setHasErrors(!!(props.touched && props.errors));
    }, [props.errors, props.touched]);

    return (
        <InputContainer>
            <Label>{`${props.label} ${props.required ? '' : '(optional)'}`}</Label>
            <Picker
                value={props.value}
                placeholder={BLANK_OPTION}
                onValueChange={onValueChange}
                pickerProps={{
                    onBlur: handleBlur,
                    onFocus: () => setIsFocused(true),
                    testID: props.testID
                }}
                disabled={disabled}
                onOpen={() => setIsFocused(true)}
                onClose={handleBlur}
                items={options.map(option => ({ ...option, key: option.value }))}
                style={{
                    inputIOS: dropdownStyles,
                    inputWeb: dropdownStyles,
                    inputAndroid: dropdownStyles
                }}
                touchableWrapperProps={{
                    testID: `${props.testID}Trigger`
                }}
                touchableDoneProps={{
                    testID: `${props.testID}Done`
                }}
                modalProps={{
                    testID: `${props.testID}Modal`
                }}
                textInputProps={{
                    testID: `${props.testID}Input`
                }}
            />
            {hasErrors ? <Error>{props.errors}</Error> : <Buffer />}
        </InputContainer>
    );
};
