import React from 'react';
import { Heading } from '@elements/typography/Heading';
import { Paragraph } from '@elements/typography/Paragraph';
import { EmptyStateProps } from './types';
import * as S from './styles';

export const EmptyState = (props: EmptyStateProps) => (
    <S.Container>
        <S.Icon source={props.icon} />
        <Heading type="page">{props.title}</Heading>
        <Paragraph centered>{props.body}</Paragraph>
    </S.Container>
);
