import { TemplateNode } from '@elements/forms/DynamicForm/types';
import _ from 'lodash';
import fp from 'lodash/fp';
import { metrics } from '../../shared/types';
import { Event } from '@global/metrics/type-utils';
import { TaskTemplateConfig } from './types';

export const notesFormEntity: TemplateNode = {
    Type: 'TextArea',
    ID: 'Note',
    Title: 'Note',
    Value: '',
    Validators: { MaxLength: 500 }
};

export const fileUploadFormEntity: TemplateNode = {
    Type: 'FileUpload',
    ID: 'Photos',
    Title: 'Photos',
    Value: [],
    Feature: 'tasks'
};
export type TaskActionEventKey = 'status' | 'siteLocation' | 'assignee' | 'priority' | 'dueDate';

const createTaskTemplate = (
    showModalChildren: boolean,
    eventNames: Partial<Record<TaskActionEventKey, Event<typeof metrics>>>,
    { teamUserOptions, associatedGroupUserOptions, siteOptions, readonlyPhotos }: TaskTemplateConfig
): TemplateNode[] => {
    const modalChildren = showModalChildren ? [notesFormEntity, fileUploadFormEntity] : [];
    return [
        {
            Type: 'Text',
            ID: 'Title',
            Title: 'Title',
            Value: '',
            Validators: { Required: true }
        },
        {
            Type: 'ListPicker',
            ID: 'Status',
            Title: 'Status',
            Value: '',
            Validators: { Required: true },
            Options: [
                { label: 'To Do', value: 'To do', icon: 'clock-outline', color: '#63646a' },
                { label: 'In Progress', value: 'In Progress', icon: 'circle-slice-5', color: '#63646a' },
                { label: 'Blocked', value: 'Blocked', icon: 'pause-circle-outline', color: '#FF6F00' },
                { label: 'Done', value: 'Done', icon: 'check-circle-outline', color: '#43A047' },
                { label: "Won't Do", value: "Won't do", icon: 'close-circle-outline', color: '#E53935' }
            ],
            SubmitText: 'Set status',
            Icon: 'alert-circle-outline',
            IconType: 'glyphicon',
            Children: modalChildren,
            EventName: eventNames.status
        },
        {
            Type: 'ListPicker',
            ID: 'Site location',
            Title: 'Site location',
            Value: '',
            Options: siteOptions,
            SubmitText: 'Set site location',
            Icon: 'map-marker',
            IconType: 'glyphicon',
            Children: modalChildren,
            EventName: eventNames.siteLocation,
            Searchable: true
        },
        {
            Type: 'ListPicker',
            ID: 'Assigned to',
            Title: 'Assigned to',
            Value: 'Unassigned',
            SubmitText: 'Set assignee',
            Icon: 'face',
            IconType: 'avatar',
            Children: modalChildren,
            Options: [
                {
                    Label: 'My team',
                    Options: teamUserOptions
                },
                {
                    Label: 'Others',
                    Options: associatedGroupUserOptions
                }
            ],
            Searchable: true,
            EventName: eventNames.assignee
        },
        {
            Type: 'ListPicker',
            ID: 'Priority',
            Title: 'Priority',
            Value: '',
            Validators: { Required: true },
            Options: [
                { label: 'Low', value: 'Low', icon: 'chevron-down-circle-outline', color: '#5C6BC0' },
                { label: 'Medium', value: 'Medium', icon: 'minus-circle-outline', color: '#FF6F00' },
                { label: 'High', value: 'High', icon: 'chevron-up-circle-outline', color: '#E53935' },
                { label: 'Critical', value: 'Critical', icon: 'alert-outline', color: '#E53935' }
            ],
            SubmitText: 'Set priority',
            Icon: 'flag',
            IconType: 'glyphicon',
            Children: modalChildren,
            EventName: eventNames.priority
        },
        {
            Type: 'DatePicker',
            ID: 'Due date',
            Title: 'Due date',
            SubmitText: 'Set due date',
            Icon: 'calendar-clock',
            IconType: 'glyphicon',
            Children: modalChildren,
            EventName: eventNames.dueDate
        },
        {
            Type: 'TextArea',
            ID: 'Description',
            Title: 'Description',
            Value: ''
        },
        { ...fileUploadFormEntity, Readonly: readonlyPhotos }
    ];
};

export const createTaskFormTemplate = _.partial(createTaskTemplate, false, {});

export const createTaskDetailsTemplate = _.flow(
    _.partial(createTaskTemplate, true),
    fp.reject(_.overSome([_.matches({ ID: 'Title' }), _.matches({ ID: 'Description' })])),
    fp.map(field => ({ ...field, Readonly: field.ID === 'Photos' }))
);
