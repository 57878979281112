import { OrderedList } from '@elements/collections/OrderedList';
import { FeedbackScreen } from '@elements/feedback/FeedbackScreen';
import { Heading } from '@elements/typography/Heading';
import { Paragraph } from '@elements/typography/Paragraph';
import _ from 'lodash';
import { AppErrorProps } from './types';

export const AppError = (props: AppErrorProps) => (
    <FeedbackScreen
        type="error"
        primaryButton={{
            text: 'OK',
            handler: _.noop,
            testID: 'OkBtn'
        }}
        title="App error"
        subtitle="Oops, something went wrong."
    >
        <Heading type="section">Error details</Heading>
        <OrderedList
            items={[
                <Paragraph key="next-steps-1">Error Message: {props.error}</Paragraph>,
                <Paragraph key="next-steps-2">Error Id: {props.errorId}</Paragraph>
            ]}
        />
        <Heading type="section">Things you can try</Heading>
        <OrderedList
            items={[
                <Paragraph key="next-steps-1">Try completing the action again</Paragraph>,
                <Paragraph key="next-steps-2">Close and restart the app</Paragraph>,
                <Paragraph key="next-steps-2">Contact HazardCo Support</Paragraph>
            ]}
        />
    </FeedbackScreen>
);
