import { Formik, FormikProps } from 'formik';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { DynamicFormProps } from './types';

import { Field } from './fields/Field';
import { mapKeysToLowerFirst } from '@global/utils/helpers/object';
import { useEffect, useReducer, useRef } from 'react';
import { ActionItemSubmission } from '../ActionItem';
import { evaluateEffects } from './condition/engine';

import { reducer } from './reducer';
import { translateEffects } from './condition/effect';
import _ from 'lodash/fp';

// A temporary workaround for exposing formik isValid state during update
// A better approach is to redesign such that submit button is included inside formik
const nextTick = useEffect;

export const DynamicForm = <T extends Record<string, unknown>>({
    template,
    resetForm,
    onChange,
    getValue,
    initialValue,
    isValidChange
}: DynamicFormProps<T>) => {
    const [{ evaluatedTemplate, trigger, validators, defaultValue }, dispatch] = useReducer(reducer, {
        evaluatedTemplate: {},
        originalTemplate: {},
        trigger: {}
    });

    useEffect(() => {
        dispatch({ type: 'INIT', payload: template });
    }, [template]);

    const formikRef = useRef<FormikProps<T>>(null);
    getValue && (getValue.current = () => formikRef?.current?.values);

    const initialValues = _.mergeAll([defaultValue, initialValue]);

    return (
        <KeyboardAwareScrollView>
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                onSubmit={() => console.log()}
                validationSchema={validators}
                initialErrors={{}}
                validateOnChange
                enableReinitialize
            >
                {formControls => {
                    if (resetForm) {
                        resetForm.current = formControls.resetForm;
                    }

                    nextTick(() => {
                        isValidChange?.(formControls.isValid && formControls.dirty);
                    }, [formControls.isValid, formControls.dirty]);

                    const onValueChange = (fieldAccessKey: string, changedValue: unknown) => {
                        const effects = evaluateEffects(
                            formControls.values,
                            fieldAccessKey,
                            changedValue as any,
                            trigger
                        );
                        dispatch({
                            type: 'UPDATE',
                            template: translateEffects(effects) as any
                        });

                        formControls.setFieldValue(fieldAccessKey, changedValue);

                        return Promise.resolve();
                    };

                    const onModalValueChanged = (
                        fieldAccessKey: string,
                        core: ActionItemSubmission,
                        children?: ActionItemSubmission[]
                    ) => {
                        onChange?.(core, children);
                        onValueChange(fieldAccessKey, core.value);
                    };

                    return Object.values(evaluatedTemplate)
                        .map(({ ID, ...props }) => ({ id: ID, ...props }))
                        .map(mapKeysToLowerFirst)
                        .map(field => (
                            <Field
                                key={`DF-FLD-${field.id}`}
                                {...{ ...field, formControls, onValueChange, onModalValueChanged }}
                            ></Field>
                        ));
                }}
            </Formik>
        </KeyboardAwareScrollView>
    );
};
