import { HeadingProps } from './types';
import * as S from './styles';

export const Heading = ({ children, type, ...props }: HeadingProps) => {
    switch (type) {
        case 'page':
            return <S.PageHeading {...props}>{children}</S.PageHeading>;
        case 'section':
            return <S.SectionHeading {...props}>{children}</S.SectionHeading>;
    }
};
