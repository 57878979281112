import { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import { CarouselRenderItemInfo } from 'react-native-reanimated-carousel/lib/typescript/types';
import { ImageDimensions, CarouselItemProps } from './types';
import { getImageDimensions } from './helpers';
import * as S from './styles';

export const CarouselItem = ({ item }: CarouselRenderItemInfo<string> | CarouselItemProps) => {
    const [dimensions, setDimensions] = useState<ImageDimensions>({ height: 0, width: 0 });
    const { height, width } = Dimensions.get('window');

    useEffect(() => {
        getImageDimensions(item).then(image => {
            const ratio = image.height / image.width;
            setDimensions({ width, height: width * ratio });
        });
    }, [item, height, width]);

    return (
        <S.Container>
            <S.Image {...dimensions} source={{ uri: item }} />
        </S.Container>
    );
};
