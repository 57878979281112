import { TemplateNode } from '@elements/forms/DynamicForm/types';
import _ from 'lodash/fp';

type Props = {
    Expires: boolean;
    Feature: string;
};

const expireFieldTemplate = {
    ID: 'expires',
    Title: 'Expiration Date',
    Type: 'DateField' as const,
    Validators: { Required: true }
};

const baseTemplate = {
    Type: 'SubForm' as const,
    Children: [
        {
            ID: 'photo',
            Title: 'Provide Evidence',
            Type: 'FileUpload' as const,
            Validators: { Required: true }
        }
    ]
};

const template = (Expires: boolean, Feature: string, Title: string): Partial<TemplateNode> =>
    _.pipe(
        _.set('Children[0].Feature', Feature),
        _.set('Children[0].Title', Title),
        Expires ? _.set('Children[1]', expireFieldTemplate) : _.identity
    )(baseTemplate);

export const normaliseTemplate = ({ Expires, Feature, ...props }: TemplateNode & Props): TemplateNode => {
    return _.merge(props, template(Expires, Feature, props.Title));
};
