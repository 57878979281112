import { useEffect, useReducer, useState } from 'react';
import { DynamicForm } from '@elements/forms/DynamicForm';
import { Button } from '@elements/fundamentals/Button';
import { FooterButtons } from '@elements/layout/FooterButtons';
import { Screen } from '@elements/layout/Screen';
import { Heading } from '@elements/typography/Heading';
import { Paragraph } from '@elements/typography/Paragraph';
import { TaskFormPageProps } from './types';
import { TaskFormModel } from '../../shared/types';
import { TasksService } from '../../services/tasks';
import { useUserContext } from '@context/UserProvider';
import { useBackgroundActivties } from '@context/BackgroundActivityProvider';
import { patchWithDropdownOption } from './helpers';
import { reducer } from './state';
import { useChannelsContext } from '@global/utils/nativescriptHost/ChannelsProvider';
import { useFormValue } from '@elements/forms/DynamicForm/hooks';
import { map } from '@global/utils/fp/maybe';
import _ from 'lodash/fp';
import axios from 'axios';
import Toast from 'react-native-root-toast';
import { MaterialIcons } from '@expo/vector-icons';
import { isEmbedded } from '@shared/helpers';

export const TaskFormPage = ({ metrics: { screenName, events }, ...props }: TaskFormPageProps) => {
    const { user, loading, sites, groupUsers, associatedGroupUsers } = useUserContext();
    const [isLoading, setIsLoading] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const eventSender = useChannelsContext();
    const { isActivityBlocked } = useBackgroundActivties();
    const [formValueRef, getFormValue] = useFormValue<TaskFormModel>();

    const [{ formTemplate, teamAssigneeOptions, siteOptions, otherAssigneeOptions }, dispatch] = useReducer(reducer, {
        formTemplate: [],
        teamAssigneeOptions: [],
        otherAssigneeOptions: [],
        siteOptions: []
    });

    useEffect(() => {
        if (loading || !user.isAuthenticated || !user.Group) return;

        dispatch({
            type: 'INIT',
            users: groupUsers,
            associatedGroupUsers,
            sites,
            submitType: props.submitAction
        });
        setIsLoading(false);
    }, [user, loading, sites, groupUsers, associatedGroupUsers, props.submitAction]);

    const handleCancel = () => {
        eventSender?.logEvent({ event: events.cancel });
        props.navigation.goBack();
    };

    const handleAdd = async () => {
        eventSender?.logEvent({ event: events.mainAction });

        _.pipe(
            getFormValue,
            map(async formValue => {
                const taskDTO = TasksService.mapToDTO(formValue, props.GUID);
                const patchedTaskDTO = {
                    ...taskDTO,
                    ...patchWithDropdownOption(taskDTO.Assignee, [...teamAssigneeOptions, ...otherAssigneeOptions], {
                        label: 'AssigneeName',
                        value: 'Assignee'
                    }),
                    ...patchWithDropdownOption(taskDTO.ProjectGUID, siteOptions, {
                        label: 'Address',
                        value: 'ProjectGUID'
                    })
                };
                try {
                    setIsLoading(true);
                    await TasksService[props.submitAction](patchedTaskDTO);
                    props.onSubmit({
                        assignee: patchedTaskDTO.AssigneeName,
                        location: patchedTaskDTO.Address,
                        title: formValue.Title ?? ''
                    });
                } catch (error: unknown) {
                    // TODO replace with generic error handler & override to show toast message
                    if (axios.isAxiosError(error) && error.response?.status === 403) {
                        Toast.show('Please upgrade your plan to perform this feature.', {
                            duration: Toast.durations.LONG,
                            position: Toast.positions.CENTER
                        });
                    } else {
                        props.navigation.navigate('Server error');
                    }
                }
                setIsLoading(false);
            })
        )();
    };

    return (
        <Screen
            screenName={screenName}
            isLoading={isLoading}
            footer={
                <FooterButtons>
                    <Button halfSize onPress={handleCancel} type="secondary" testID="BackBtn">
                        Cancel
                    </Button>
                    <Button
                        disabled={isActivityBlocked('SUBMIT_FORM') || !isValid}
                        halfSize
                        onPress={handleAdd}
                        type="primary"
                        testID={props.primaryButton.testID}
                    >
                        {props.primaryButton.label}
                    </Button>
                </FooterButtons>
            }
        >
            <Heading type="page">
                {props.heading}
                {isEmbedded() && <MaterialIcons name="close" size={24} color="black" onPress={handleCancel} />}
            </Heading>
            <Paragraph>{props.subheading}</Paragraph>
            <DynamicForm
                template={formTemplate}
                initialValue={props.initialData}
                getValue={formValueRef}
                isValidChange={setIsValid}
            />
        </Screen>
    );
};
