import { LinkingOptions } from '@react-navigation/native';
import { CredentialModuleParams, credentialScreens } from '../modules/credential/routing';
import { tasksScreens } from '../modules/tasks/routing';
import { ServerError } from './screens/ServerError';
import _ from 'lodash';
import { ModuleScreen } from './types';
import { SampleModuleParams } from '../modules/sample/routing';
import { ftmScreens } from '../modules/ftm/routing';

export type GlobalModulesParams = {
    'Server error': undefined;
    Home: undefined;
} & CredentialModuleParams &
    SampleModuleParams;

export const globalScreens: ModuleScreen = {
    screens: [{ name: 'Server error', component: ServerError, type: 'headerless', route: 'server-error' }],
    moduleName: 'globalGroup'
};

export const modularizedScreens = [globalScreens, credentialScreens, tasksScreens, ftmScreens];

export const appLinking: LinkingOptions<GlobalModulesParams> = {
    prefixes: [],
    config: {
        screens: {
            Home: 'home',
            ..._(modularizedScreens)
                .flatMap('screens')
                .map(x => [x.name, x.route])
                .fromPairs()
                .value()
        }
    }
};
