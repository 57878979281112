import styled from 'styled-components/native';
import { StyledButtonProps, ButtonProps, BUTTON_COLORS } from './types';

export const Button = styled.TouchableOpacity<ButtonProps>`
    padding: 16px;
    background: ${({ type, disabled }) => (disabled ? '#D4D4D4' : BUTTON_COLORS[type].background)};
    width: ${({ halfSize }) => (halfSize ? '49%' : '100%')};
    align-items: center;
    align-self: center;
    border: solid 1px ${({ type, disabled }) => (disabled ? 'transparent' : BUTTON_COLORS[type].border)};
`;

export const Floating = styled.TouchableOpacity<ButtonProps>`
    background: ${BUTTON_COLORS.floating.background};
    flex-shrink: 1;
    justify-content: center;
    padding: 12px 24px;
    border-radius: 32px;
    margin: 16px auto 32px auto;
    flex-shrink: 0;
`;

export const ButtonText = styled.Text<StyledButtonProps>`
    font-weight: 600;
    font-size: 16px;
    flex-direction: row;
    display: flex;
    align-items: center;
    color: ${({ type }) => BUTTON_COLORS[type].text};
`;
