import _ from 'lodash';
import { Fragment } from 'react';
import { mapOptionsToRadioConfig } from '../../helpers';
import { RadioButtonsProps } from './types';
import { Radio } from '../Radio';
import * as RadioStyles from '../Radio/styles';

export const RadioButtons = ({ options, onChange, selected, iconType, testID, disabled }: RadioButtonsProps) => (
    <Fragment>
        {options.length ? (
            _.zip(options, mapOptionsToRadioConfig(options, selected)).map(([radio, option]) => {
                if (!radio || !option) return;

                const { color, icon } = radio;
                return (
                    <Radio
                        key={option.id}
                        icon={{ icon, iconColor: color, iconType, style: 'small' }}
                        {...{ ...option, onPress: onChange, testID, disabled }}
                    />
                );
            })
        ) : (
            <RadioStyles.NotPressable>
                <RadioStyles.Title>No options are available</RadioStyles.Title>
            </RadioStyles.NotPressable>
        )}
    </Fragment>
);
