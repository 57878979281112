import { PropsWithChildren } from 'react';
import { ButtonProps as NativeProps } from 'react-native';

export type ButtonProps = Omit<PropsWithChildren<NativeProps & StyledButtonProps>, 'title'> & {
    title?: string;
};

export type StyledButtonProps = {
    type: ButtonTypes;
    halfSize?: boolean;
};

export type ButtonTypes = 'primary' | 'secondary' | 'floating';

export const BUTTON_COLORS: Record<ButtonTypes, Record<'background' | 'text' | 'border', string>> = {
    primary: {
        background: '#0091EA',
        text: '#ffffff',
        border: '#0091EA'
    },
    secondary: {
        background: '#FAFBFA',
        text: '#000000',
        border: '#D4D4D4'
    },
    floating: {
        background: '#0091EA',
        text: '#ffffff',
        border: '#0091EA'
    }
};
