import styled from 'styled-components';
import { getInputBackgroundColor, getInputBorderColor, getInputTextColor } from '../../util';
import { StyledDateFieldProps } from '../types';

export const DateInput = styled.input.attrs<StyledDateFieldProps>(props => ({
    'data-test-id': props.testID
}))<StyledDateFieldProps>`
    border: solid 2px ${({ hasErrors, isFocused, disabled }) => getInputBorderColor(hasErrors, isFocused, disabled)};
    background-color: ${({ disabled }) => getInputBackgroundColor(disabled)};
    color: ${({ disabled }) => getInputTextColor(disabled)};
    padding: 16px;
    border-radius: 2px;
    font-size: 16px;
    height: 20px;
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    -webkit-appearance: none;

    &::-webkit-date-and-time-value {
        text-align: left;
    }
`;
