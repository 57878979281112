import { ScrollView } from 'react-native-gesture-handler';
import { LoadingScreen } from '@elements/feedback/LoadingScreen';
import { ScreenProps } from './types';
import * as S from './styles';
import { useLogScreenName } from '@global/metrics/hooks';

export const Screen = (props: ScreenProps) => {
    // Store the main Screen contents in a variable to avoid repetition
    const screenInner = (
        <S.Screen testID="Screen" background={props.background}>
            {props.static ? (
                <S.StaticView testID="StaticView">{props.children}</S.StaticView>
            ) : (
                <ScrollView testID="ScrollView" keyboardShouldPersistTaps="handled">
                    {props.children}
                </ScrollView>
            )}
            {props.footer}
        </S.Screen>
    );

    useLogScreenName(props.screenName);

    return (
        <>
            {props.background ? (
                <S.Background testID="Background" source={props.background}>
                    {screenInner}
                </S.Background>
            ) : (
                screenInner
            )}
            <S.BottomSafeArea />
            {props.isLoading && <LoadingScreen />}
        </>
    );
};
